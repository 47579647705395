import * as React from "react";
import * as Redux from "react-redux";
import * as Editor from "../../reducers/editor";
import { darken } from "polished";
import { color3 } from "../../global-styles";
import { EditorWrapper } from "../editor/editor-wrapper";
import TopBar from "../editor/top-bar";
import { PlayIndicator } from "../editor/play-indicator";
import * as player from "../../reducers/player";
import * as Hooks from "../../hooks";

// Needed for displaying the pattern on timeline
// Needed to calculate the animation end of the play indicator
const BAR_SIZE = 5;

export function CreateTimeline() {
  const timelineBarsAmount = Redux.useSelector(Editor.getTimelineLoopLength);
  const isPlaying = Redux.useSelector((state) =>
    player.isPlaying(state.player, "editor", "timeline")
  );
  const patterns = Redux.useSelector(Editor.getPatterns);

  return (
    <EditorWrapper>
      <TopBar playType="timeline" />
      <div
        style={{
          position: "relative",
          overflowX: "auto",
          maxWidth: "100%",
        }}
      >
        <div
          style={{
            // Always make it a bit longer than the last pattern so the user can add more
            minWidth: `${timelineBarsAmount * 5 + 300}px`, // 100px: Width of the channel names
          }}
        >
          {(Object.entries(patterns) || []).map(([patternId, pattern], i) => (
            <TimelinePatterns
              patternId={patternId}
              pattern={pattern}
              key={`${patternId}-${pattern.name}-${i}`}
            />
          ))}
          {isPlaying && (
            <PlayIndicator
              animationStart="100px" // 100px: Witdh of the channel names
              animationEnd={`calc(100px + ${
                (timelineBarsAmount || 0) * BAR_SIZE
              }px)`} // 35px: Width of the channel settings (+)
            />
          )}
        </div>
      </div>
    </EditorWrapper>
  );
}

function TimelinePatterns(props) {
  const dispatch = Redux.useDispatch();
  const [currentPatternId, setCurrentPatternId] = Hooks.useCurrentPattern();
  const timeline = Redux.useSelector(Editor.getTimeline);
  const timelinePatterns = Object.values(timeline.patterns).filter(
    (timelinePattern) => {
      return timelinePattern.patternId == props.patternId;
    }
  );

  // Ref for the clickable area
  const clickableAreaRef = React.useRef(null);

  // Define the BAR_SIZE constant
  const BAR_SIZE = 5; // Each segment is 5px

  const handleClick = (event) => {
    const element = clickableAreaRef.current;
    if (element == event.target) {
      const bounds = element.getBoundingClientRect();
      const relativeX = event.clientX - bounds.left;
      const startTime = Math.round(relativeX / BAR_SIZE);
      dispatch(Editor.addTimelinePattern(props.patternId, startTime));
    }
  };

  return (
    <div
      style={{
        display: "flex",
        minHeight: "72px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100%",
          fontSize: "13px",
          background: "#6a6a6d",
          color: "white",
          width: "100px",
          boxSizing: "border-box",
          padding: "5px",
          overflowWrap: "anywhere",
          wordWrap: "break-word",
          textAlign: "center",
          cursor: "pointer",

          position: "sticky",
          minHeight: "inherit",
          left: "0px",
          top: "0px",
          zIndex: "1",
        }}
        onClick={() => {
          setCurrentPatternId(props.patternId);
        }}
      >
        {props.pattern.name}
      </div>
      <div
        ref={clickableAreaRef}
        style={{
          position: "relative",
          display: "flex",
          flex: 1,
          cursor: "cell",
        }}
        onClick={handleClick}
      >
        {timelinePatterns.map((pattern, i) => (
          <TimelinePattern
            key={`${pattern.patternId}-${pattern.startTime}-${i}`}
            pattern={pattern}
          />
        ))}
      </div>
    </div>
  );
}

function TimelinePattern(props) {
  const dispatch = Redux.useDispatch();
  const [leftPosition, setLeftPosition] = React.useState(
    props.pattern?.startTime * BAR_SIZE
  );
  const [isDragging, setIsDragging] = React.useState(false);
  const [startX, setStartX] = React.useState(0);
  const patternRef = React.useRef(null);

  // Handler for mouse down event
  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.clientX - leftPosition);
  };

  // Handler for mouse move event
  const handleMouseMove = (e) => {
    if (isDragging) {
      const newPosition = e.clientX - startX;
      const snappedPosition = Math.round(newPosition / BAR_SIZE) * BAR_SIZE;
      setLeftPosition(Math.max(snappedPosition, 0));
    }
  };

  // Handler for mouse up event
  const handleMouseUp = (e) => {
    if (patternRef.current && patternRef.current.contains(e.target)) {
      setIsDragging(false);
      dispatch(
        Editor.updateTimelinePatternStart(
          props.pattern.patternId,
          props.pattern.startTime,
          leftPosition / BAR_SIZE
        )
      );
    }
  };

  // Handler for right click event
  const handleRightClick = (e) => {
    e.preventDefault();
    dispatch(
      Editor.removeTimelinePattern(
        props.pattern.patternId,
        props.pattern.startTime
      )
    );
  };

  React.useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging, startX, leftPosition]);

  // Todo: Bar length should be set on the pattern level
  const barsArray = Array.from(Array(Editor.PATTERN_LOOP_LENGTH).keys());

  return (
    <div
      ref={patternRef}
      style={{
        position: "absolute",
        top: "0px",
        left: `${leftPosition}px`,
        height: "100%",
        background: "rgb(33, 125, 212)",
        boxShadow:
          "inset 0.3px 0.3px currentColor, inset -0.3px -0.3px currentColor",
        color: "black",
        borderRadius: "5px",
        cursor: isDragging ? "grabbing" : "grab",
      }}
      onMouseDown={handleMouseDown}
      onContextMenu={handleRightClick}
    >
      <div
        style={{
          position: "relative",
        }}
      >
        {(props.pattern?.channels || []).map((channel, i) => (
          <div
            key={i}
            style={{
              height: `${BAR_SIZE}px`,
            }}
          >
            {barsArray.map((barPosition) => (
              <div
                key={barPosition}
                style={{
                  display: "inline-block",
                  backgroundColor: channel?.bars[barPosition]?.selected
                    ? "rgb(136 175 255)"
                    : "transparent",
                  width: `${BAR_SIZE}px`,
                  height: `${BAR_SIZE}px`,
                }}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}