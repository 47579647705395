export const eq = {
  name: "Parametric EQ",
  moduleUrl: "/processors/eqProcessor.js",
  processorName: "eq-processor",
  settings: {
    bufferSize: 1024,
    sampleRate: 44100,
  },
  parameters: {
    lowShelfFrequency: {
      value: 100,
      range: { min: 20, max: 1000 },
    },
    lowShelfGain: {
      value: 0,
      range: { min: -12, max: 12 },
    },
    lowMidFrequency: {
      value: 400,
      range: { min: 100, max: 2000 },
    },
    lowMidQ: {
      value: 1,
      range: { min: 0.1, max: 10 },
    },
    lowMidGain: {
      value: 0,
      range: { min: -12, max: 12 },
    },
    highMidFrequency: {
      value: 2000,
      range: { min: 500, max: 8000 },
    },
    highMidQ: {
      value: 1,
      range: { min: 0.1, max: 10 },
    },
    highMidGain: {
      value: 0,
      range: { min: -12, max: 12 },
    },
    highShelfFrequency: {
      value: 8000,
      range: { min: 2000, max: 20000 },
    },
    highShelfGain: {
      value: 0,
      range: { min: -12, max: 12 },
    },
  },
};
