import styled from "@emotion/styled/macro";
import { flexbox } from "styled-system";
import * as R from "ramda";
import { withProps } from "recompose";

const Component = styled("div")(`display:flex;`, flexbox);

export const Flex = R.compose(
  withProps(
    (props) =>
      props.center && {
        justifyContent: "center",
        alignItems: "center",
      }
  )
)(Component);
