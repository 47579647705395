import React from "react";
import {
  CHANNEl_TYPE_RATE,
  CHANNEl_TYPE_VOLUME,
  CHANNEL_TYPE_SAMPLE_SLICER,
  CHANNEL_TYPE_EFFECTS,
} from "../../constants";
import { Box } from "../common/box";
import { SampleSlicer } from "./sample-slicer";
import { BarControls } from "./bar-controls";
import { Effects } from "./effects";
import { getAudioBlobUrl } from "../../middlewares/sound-middleware";

const controlTypes = [
  {
    name: "Pitch",
    id: CHANNEl_TYPE_RATE,
  },
  {
    name: "Volume",
    id: CHANNEl_TYPE_VOLUME,
  },
  {
    name: "Sample slicer",
    id: CHANNEL_TYPE_SAMPLE_SLICER,
  },
  {
    name: "Effects",
    id: CHANNEL_TYPE_EFFECTS,
  },
];

export function ChannelSettings(props) {
  const [controlType, setControlType] = React.useState(controlTypes[0].id);

  return (
    <Box display="flex" flexDirection="column" flexGrow="1">
      <Box
        display="flex"
        boxShadow="inset 0px -1px 0px 0px #4c4c4c"
        backgroundColor="#565859"
      >
        {controlTypes.map((type) => (
          <Box
            key={type.id}
            onClick={() => setControlType(type.id)}
            cursor="pointer"
            fontSize="14px"
            padding="5px"
            paddingTop="7px"
            paddingLeft="7px"
            paddingRight="7px"
            marginTop="4px"
            marginLeft="5px"
            borderTopLeftRadius="5px"
            borderTopRightRadius="5px"
            boxShadow="inset 0 1px 0px 0px #4c4c4c, inset -1px 0px 0px 0px #4c4c4c, inset 1px 0px 0px 0px #4c4c4c;"
            backgroundColor={controlType === type.id ? "#646464" : ""}
            color={controlType === type.id ? "#cfcbcb" : "black"}
            opacity={controlType === type.id ? 1 : 0.6}
          >
            {type.name}
          </Box>
        ))}
      </Box>

      {(controlType === CHANNEl_TYPE_RATE ||
        controlType === CHANNEl_TYPE_VOLUME) && (
        <BarControls channelId={props.channelId} controlType={controlType} />
      )}

      {controlType === CHANNEL_TYPE_SAMPLE_SLICER && (
        <Box height="100%">
          <SampleSlicer
            channelId={props.channelId}
            // getAudioBlobUrl is used for newly recorded auio
            audioUrl={props.audioUrl || getAudioBlobUrl(props.soundId)}
            soundId={props.soundId}
          />
        </Box>
      )}

      {controlType === CHANNEL_TYPE_EFFECTS && (
        <Effects howlerSoundId={props.soundId} channelId={props.channelId} />
      )}
    </Box>
  );
}
