import { store } from "../index";
import { getUser } from "./reducers/main";
import * as editor from "./reducers/editor";
import * as R from "ramda";
import { clientConfig } from "./client-config";

const CHANNELS_STORAGE_KEY = "CURRENT_CHANNELS";

export function saveTrack(props) {
  // Check if logged in
  // - Logged in: Save on server
  // - Not logged in: Save in local storage

  //localStorage.setItem(CHANNELS_STORAGE_KEY, JSON.stringify(channels))
  const state = store.getState();
  const track = editor.getTrack(state.editor);

  fetch(`${clientConfig.apiUrl}/api/postgres/save-track`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      user: getUser(state.main),
      trackData: R.omit("editing", track),
      originalTrackId: R.path(["originalTrackId"], props),
    }),
  });
}

export function deleteTrack(id) {
  const state = store.getState();

  fetch(`${clientConfig.apiUrl}/api/postgres/delete-track`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      id,
      user: getUser(state.main),
    }),
  });
}

export function fetchTrack(trackId, config) {
  fetch(`${clientConfig.apiUrl}/api/postgres/get-track?id=${trackId}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      !json.error && config.success && config.success(json);
      json.error && config.fail && config.fail(json);
    });
}

export function fetchTracks(query, config) {
  fetch(`${clientConfig.apiUrl}/api/postgres/get-tracks`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(query),
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      !json.error && config.success && config.success(json);
      json.error && config.fail && config.fail(json);
    });
}

//Change to a request
export function getSavedTrack(callback) {
  const savedChannels = JSON.parse(localStorage.getItem(CHANNELS_STORAGE_KEY));
  callback(savedChannels);
}

export function fetchAllTracks(success, fail) {
  fetch(`${clientConfig.apiUrl}/api/all-tracks`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      !json.error && success && success(json);
      json.error && fail && fail(json);
    });
}

// Async / await below (the ones above should be converted)

export async function fetchUser(userId) {
  try {
    const response = await fetch(`${clientConfig.apiUrl}/api/postgres/get-user?id=${userId}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    const json = await response.json();
    if (!json.error) {
      return json; // Return the JSON response directly
    } else {
      throw new Error(json.error); // Throw an error if there's an issue
    }
  } catch (error) {
    console.error("Error fetching user:", error);
    throw error; // Rethrow the error for the caller to handle
  }
}

export async function saveUser(userData) {
  try {
    const response = await fetch(`${clientConfig.apiUrl}/api/postgres/save-user`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userData,
      }),
    });
    const json = await response.json();
    if (!json.error) {
      return json; // Return the JSON response directly
    } else {
      throw new Error(json.error); // Throw an error if there's an issue
    }
  } catch (error) {
    console.error("Error in saveUser:", error);
    throw error; // Rethrow the error for the caller to handle
  }
}

export async function fetchUserData(userId) {
  try {
    const response = await fetch(`${clientConfig.apiUrl}/api/user`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId,
      }),
    });
    const json = await response.json();
    if (!json.error) {
      return json; // Return the JSON response directly
    } else {
      throw new Error(json.error); // Throw an error if there's an issue
    }
  } catch (error) {
    console.error("Error in fetchUserData:", error);
    throw error; // Rethrow the error for the caller to handle
  }
}
