import styled from "@emotion/styled/macro";
import {
  typography,
  space,
  color,
  layout,
  flexbox,
  border,
  system,
  boxShadow,
  position,
  grid,
} from "styled-system";

export const Box = styled("div")(
  position,
  border,
  layout,
  typography,
  space,
  color,
  flexbox,
  boxShadow,
  grid,
  system({
    cursor: true,
  }),
  (props) =>
    props.hover && {
      "&:hover": props.hover,
    }
);
