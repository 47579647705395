export function isPlaying(state, id, playType) {
  const isCorrectPlayType = playType === state.playType || !playType;

  if (id) {
    return state.playing && getId(state) === id && isCorrectPlayType;
  }
  return state.playing && isCorrectPlayType;
}

export function getPlayType(state) {
  return state.playType;
}

export function getId(state) {
  return state.id;
}

export function getCurrentBar(state, id) {
  if (id) {
    if (getId(state) === id) {
      return state.currentBar;
    } else {
      return undefined;
    }
  }
  return state.currentBar;
}
