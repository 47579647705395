import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as player from "../../reducers/player";
import * as editor from "../../reducers/editor";
import Dilla from "dilla";
import {
  BARS_SECTION_AMOUNT,
  BARS_PER_SECTION_AMOUNT,
  PLAY_CHANNEL_EVENT,
} from "../../constants";

const AudioClock = ({
  isPlaying,
  playType,
  loopLength,
  currentBar,
  updateCurrentBar,
  resetCurrentBar,
  track
}) => {
  const dillaRef = React.useRef(null);

  React.useEffect(() => {
    // Initialize AudioContext and Dilla only once
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    const audioContext = new AudioContext();
    const dilla = new Dilla(audioContext);
    dilla.setTempo(track.bpm);
    dilla.setBeatsPerBar(1 / 4);

    dillaRef.current = { dilla, audioContext };

    // Cleanup function for component unmount
    return () => {
      dilla.stop();
    };
  }, []);

  React.useEffect(() => {
    if(dillaRef.current.dilla) {
      dillaRef.current.dilla.setTempo(track.bpm);
    }
  }, [track.bpm])

  React.useEffect(() => {
    // Loop length should change if timeline
    // Todo: Should also be dynamic for pattern

    console.log("props.loopLength:", loopLength);
    dillaRef.current.dilla.setLoopLength(loopLength);
  }, [loopLength]);

  React.useEffect(() => {
    window.currentBar = currentBar;
  }, [currentBar]);

  // Note: The window.currentBar is a hack to handle
  // so this hook below is not being triggered all the time
  React.useEffect(() => {
    const { dilla, audioContext } = dillaRef.current;

    // Define the tick event handler
    const handleTick = (tick) => {
      // Right now newBar can be the same number 3 times in a row
      // Could we somehow make tick return correctly and only trigger when needed?
      // -------------------------------------------------------------------------
      // However, this can be needed for swing etc in the future
      const newBar = parseInt(tick.position, 10) - 1;
      if (newBar !== window.currentBar) {
        window.dispatchEvent(
          new CustomEvent(PLAY_CHANNEL_EVENT, {
            detail: { currentBar: newBar, playType },
          })
        );
        updateCurrentBar(newBar);
      }
    };

    // Attach the tick event handler
    dilla.on("tick", handleTick);

    // Start or stop Dilla based on isPlaying
    if (isPlaying) {
      audioContext.resume().then(() => {
        dilla.start();
      });
    } else {
      dilla.stop();
      resetCurrentBar();
    }

    // Cleanup function to remove event listener
    return () => {
      dilla.off("tick", handleTick);
    };
  }, [isPlaying, updateCurrentBar, resetCurrentBar, playType]);

  // Component does not render anything
  return null;
};

// Todo: Experiment to see if this is more performant
// Since currentBar is updating so frequently,
// would it be possible to make independent of Redux?
// ------------------- Example below ----------------
// 1. This component is moved outside the Redux provider
// 2. This component:
//   - Gets updates via window events
//   - Sends updates via window events
const mapStateToProps = (state) => {
  const playType = player.getPlayType(state.player);
  return {
    playType,
    loopLength:
      playType === "timeline"
        ? editor.getTimelineLoopLength(state)
        : BARS_SECTION_AMOUNT * BARS_PER_SECTION_AMOUNT,
    isPlaying: player.isPlaying(state.player),
    currentBar: player.getCurrentBar(state.player),
    track: editor.getTrack(state.editor)
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateCurrentBar: player.updateCurrentBar,
      resetCurrentBar: player.resetCurrentBar,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AudioClock);
