import * as React from "react";
import * as Redux from "react-redux";
import * as editor from "../../reducers/editor";
import { getHowlerSoundNode } from "../../middlewares/sound-middleware";
import { SoundWrapper } from "../../effects";
import { useInstances } from "../../../instances-provider";
import { getAudioSource } from "../../effects/audio-sources";

// Different events and what should happen
// - New track loaded: Apply existing effects
// - New effect added: Apply effect
// - Update affected effect: Apply effect

// Didn't exist yet in old solution (but fix later)
// - Effect is removed: Remove effect

export function ApplyEffects() {
  const effectsChannels = Redux.useSelector((state) =>
    editor.getEffectsChannels(state)
  );

  return (Object.entries(effectsChannels) || []).map(
    ([effectChannelKey, effectChannel]) => (
      <ApplyEffect
        key={effectChannel.id + effectChannelKey}
        effectChannelKey={effectChannelKey}
        effectChannel={effectChannel}
      />
    )
  );
}

function ApplyEffect(props) {
  const channels = Redux.useSelector((state) =>
    editor.getChannels(state.editor)
  );
  const channelsWithEffect = React.useMemo(
    () =>
      channels.filter((channel) => channel.effectId === props.effectChannelKey),
    [channels]
  );

  return (
    <>
      {(channelsWithEffect || []).map((channel) => (
        <ApplyEffectsToChannel
          key={channel.id}
          soundId={channel.soundId}
          channelId={channel.id}
          effectChannel={props.effectChannel}
          effectChannelKey={props.effectChannelKey}
        />
      ))}
    </>
  );
}

function ApplyEffectsToChannel(props) {
  return (
    <>
      {props.effectChannel.effects?.map((effect) => (
        <ApplyEffectToChannel
          key={effect.id}
          soundId={props.soundId}
          channelId={props.channelId}
          effectChannelKey={props.effectChannelKey}
          effect={effect}
        />
      ))}
    </>
  );
}

// Todo: Not working to disconnect node yet
// Potentially connect and disconnect could happen within SoundWrapper (probably the best idea)
function ApplyEffectToChannel(props) {
  const instances = useInstances();

  React.useEffect(() => {
    let isMounted = true;
    let instance = null;

    const createEffectInstance = async () => {
      const howlerSoundNode = getHowlerSoundNode(props.soundId);
      const mySound = new SoundWrapper(howlerSoundNode, Howler.ctx);

      // Await the asynchronous addEffect method
      const instanceNode = await mySound.addEffect(props.effect.id, {});
      instances.effects.add(props.channelId, props.effect.id, mySound);

      // This is done to make sound connect to effectChannelKey (ex "1") (which is connected to master)
      const channel1Node = getAudioSource(props.effectChannelKey);

      if (instanceNode) {
        // Connect the instanceNode after ensuring it's created
        instanceNode.connect(channel1Node);
      } else {
        console.error("Failed to create effect instance node.");
      }

      instance = mySound;
    };

    createEffectInstance();

    return () => {
      isMounted = false;
      if (instance) {
        instance.removeEffect();
      }
    };
  }, [props.soundId, props.effect.id]);

  return null;
}
