import React from "react";
import { connect } from "react-redux";
import PlayChannel from "./play-channel";
import AudioClock from "./audio-clock";
import * as player from "../../reducers/player";
import * as editor from "../../reducers/editor";
import * as tracks from "../../reducers/tracks";
import { ApplyEffects } from "./apply-effects";

function _TrackPlayer(props) {
  return (
    <React.Fragment>
      <AudioClock />
      <ApplyEffects />
      {props.channels.map((channel, i) => (
        <PlayChannel
          key={i}
          id={channel.id ?? channel.channelId}
          // Instead of 0 we should get current pattern displayed in editor
          patternId={channel.patternId ?? props.patternId}
          patternStartTime={channel.patternStartTime} // Only for timeline (inherited from pattern)
        />
      ))}
    </React.Fragment>
  );
}

// 1. Pattern or timeline playing?
//  - Todo: Set playType in player state
// 2. For timeline:
//  - Todo:
//    - Get total tick length of track (in bars)
//    - Get patterns from timeline that are supposed to be played
//      - startTime should be in ticks (not real time)
//      - Important: Pattern should be possible to be played in the middle as well (not only from the beginning)
//      - Filter so only channels containing filled bars are played
//    - Finally return channels here in props below to be played (watch out for performance issues)
const mapStateToProps = (state) => {
  const patternId = editor.getCurrentPatternId(state);
  // For timeline we also need to
  // - Get channels from timeline
  // - Filter:
  //   - Only channels containing filled bars should be played
  //   - Only channels that are from pattern that is within playing time
  //   Note: With filtering, this means that channels will get different order
  //   Maybe channels should get id like: "${patternId}-${channelId}"

  return {
    patternId,
    channels: getChannels(state),
  };
};

function getChannels(state) {
  const playType = player.getPlayType(state.player);
  const trackId = player.getId(state.player);
  const isEditor = trackId === "editor";

  if (playType === "pattern") {
    return isEditor
      ? editor.getChannels(state.editor)
      : tracks.getChannels(state.tracks, trackId);
  } else if (playType === "timeline") {
    const timelineChannels = editor.getTimelineChannels(state);
    return timelineChannels;
  }

  return [];
}

export const TrackPlayer = connect(mapStateToProps)(_TrackPlayer);
