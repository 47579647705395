import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  updateSoundbank,
  addChannel,
  updateUser,
  isAuthenticated,
} from "../reducers/main";
import { USER_STORAGE_KEY } from "../constants";
import * as R from "ramda";
import * as requests from "../requests";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { LandingPage } from "./landing-page";
import { CreateTrack } from "./create-track";
import { UserPage } from "./user-page";
import * as Middlewares from "../middlewares";
import { addMiddleware } from "redux-dynamic-middlewares";
import { EmbedPattern } from "./embed-pattern";
import * as Hooks from "../hooks";
import Companion from "./companion";

const AppRoutes = (props) => {
  const hasAccess = Hooks.usePasswordProtection(
    "06b99d6e8d4dc4ea3df53b9c0f1faee0a5a714091f7e2fcd7e6b6c4dc1a6cf1e"
  );

  if (!hasAccess) {
    return null;
  }

  return (
    <Router>
      <RoutesContent {...props} />
    </Router>
  );
};

function RoutesContent(props) {
  const location = useLocation();
  const isCompanionRoute = location.pathname.startsWith("/companion");

  if (isCompanionRoute) {
    return (
      <Routes>
        <Route path="/companion/:id?" element={<Companion />} />
      </Routes>
    );
  }

  return <NormalRoutes {...props} />;
}

function NormalRoutes(props) {
  const fetchAndUpdateSoundBank = Hooks.useAndFetchSoundbank();

  useEffect(() => {
    addMiddleware(Middlewares.sound.middleware());

    // Login stored user
    const storedUser = localStorage.getItem(USER_STORAGE_KEY);
    if (!props.isAuthenticated && storedUser) {
      props.onLogin(JSON.parse(storedUser));
    }

    fetchAndUpdateSoundBank.fetch();
  }, []);

  if (!fetchAndUpdateSoundBank.data) {
    return null;
  }

  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/create" element={<CreateTrack />} />
      <Route path="/embed" element={<EmbedPattern />} />
      <Route path="/user/:id?" element={<UserPage />} />
    </Routes>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: isAuthenticated(state.main),
});

const mapDispatchToProps = (dispatch) => {
  const state = bindActionCreators(
    {
      updateSoundbank,
      addChannel,
      loadSounds: Middlewares.sound.loadSounds,
    },
    dispatch
  );

  return R.merge(state, {
    onLogin: async (storedUser) => {
      const res = await requests.fetchUser(storedUser.googleId);
      const userExists = res.id;
      if (userExists) {
        console.log("User exists");
        dispatch(updateUser(res));
      }
    },
  });
};

export default connect(mapStateToProps, mapDispatchToProps)(AppRoutes);
