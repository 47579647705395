import React from "react";
import * as Redux from "react-redux";
import { updateUser } from "../reducers/main";
import { USER_STORAGE_KEY } from "../constants";
import * as requests from "../requests";
import styled from "@emotion/styled";
import { GoogleLogin } from "@react-oauth/google";
import * as Hooks from '../hooks'

const Wrapper = styled.div`
  width: 245px;
`;

export function useOnGoogleLoginSuccess() {
  const dispatch = Redux.useDispatch()

  const returnFunction = React.useCallback(async (credentialResponse) => {
    const userData = await fetch(`https://www.googleapis.com/oauth2/v3/tokeninfo?id_token=${credentialResponse.credential}`).then(res => res.json())
    const googleId = userData.sub;

    const user = {
      googleId: await hashAndTruncate(googleId, 255),
      displayName: "",
    };

    async function saveAndFetchUser() {
      await requests.saveUser(user);
      const res = await requests.fetchUser(user.googleId);
      if (res.id) {
        console.log("Fetched user data after save:", res);
        localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(res));
        dispatch(updateUser(res));
      }
    }

    const res = await requests.fetchUser(user.googleId);
    console.log("user:", res);
    if (res.id) {
      console.log("User exists");
      dispatch(updateUser(res));
      localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(res));
    } else {
      console.log("User does NOT exist. Saving user...");
      await saveAndFetchUser();
    }
  }, [])

  return returnFunction
}

function Login() {
  const user = Hooks.useUser()
  const onGoogleLoginSuccess = useOnGoogleLoginSuccess()

  return (
    <Wrapper>
      <div>Is logged in: {Boolean(user).toString()}</div>
      <GoogleLogin
        onSuccess={onGoogleLoginSuccess}
        onError={() => {
          console.log("Login Failed");
        }}
      />
    </Wrapper>
  );
}

export async function hashAndTruncate(input, truncateTo = null) {
  // Convert the input string into bytes
  const encoder = new TextEncoder();
  const data = encoder.encode(input);

  // Hash the data using SHA-256
  const hashBuffer = await crypto.subtle.digest("SHA-256", data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  // Convert the bytes to hex string
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");

  // If truncateTo is specified and valid, return the truncated hash
  if (truncateTo && truncateTo > 0 && truncateTo < hashHex.length) {
    return hashHex.substring(0, truncateTo);
  }

  // Otherwise, return the full hash
  return hashHex;
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogin: async (googleId) => {
      const user = {
        googleId: await hashAndTruncate(googleId, 255),
        displayName: "",
      };

      async function saveAndFetchUser() {
        await requests.saveUser(user);
        const res = await requests.fetchUser(user.googleId);
        if (res.id) {
          console.log("Fetched user data after save:", res);
          localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(res));
          dispatch(updateUser(res));
        }
      }

      const res = await requests.fetchUser(user.googleId);
      console.log("user:", res);
      if (res.id) {
        console.log("User exists");
        dispatch(updateUser(res));
        localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(res));
      } else {
        console.log("User does NOT exist. Saving user...");
        await saveAndFetchUser();
      }
    },
  };
};

export default Redux.connect(null, mapDispatchToProps)(Login);
