import * as React from "react";
import { playSound } from "../../reducers/main";
import { connect } from "react-redux";
import * as R from "ramda";
import { PLAY_CHANNEL_EVENT } from "../../constants";
import * as player from "../../reducers/player";
import * as editor from "../../reducers/editor";
import * as tracks from "../../reducers/tracks";

function PlayChannel(props) {
  React.useEffect(() => {
    window.addEventListener(PLAY_CHANNEL_EVENT, props.play);
    return () => {
      window.removeEventListener(PLAY_CHANNEL_EVENT, props.play);
    };
  }, [props.play]);

  return null;
}

const mapStateToProps = (state, props) => {
  const trackId = player.getId(state.player);
  const isEditor = trackId === "editor";
  const playType = player.getPlayType(state.player);
  const channel = getChannel(
    state,
    props.patternId,
    props.id,
    isEditor,
    trackId,
    playType
  );

  return {
    channel,
    getBar: (currentBar) => {
      return isEditor
        ? editor.getBar(
            state.editor,
            props.patternId,
            channel.id,
            currentBar,
            playType
          )
        : tracks.getBar(
            state.tracks,
            props.patternId,
            trackId,
            channel.id,
            currentBar
          );
    },
  };
};

function getChannel(state, patternId, channelId, isEditor, trackId, playType) {
  // Todo: For play track in TrackTileList component
  // - We need to play pattern if timeline is not used yet

  if (playType === "pattern") {
    return isEditor
      ? editor.getChannel(state.editor, channelId, patternId)
      : tracks.getChannel(state.tracks, trackId, channelId);
  } else if (playType === "timeline") {
    return editor.getTimelineChannel(state, patternId, channelId);
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    play: (e) => {
      const bar = props.getBar(
        // Explanation:
        // The adjustment is for timeline
        // where patterns start time is inherited
        // by the channels, so we can play the right bar
        e.detail.currentBar - (props.patternStartTime || 0)
      );

      if (R.path(["selected"], bar)) {
        playSound(props.channel.soundId, bar.rate, bar.volume, bar.regionName);
      }
    },
  };
};

export default R.compose(
  connect(mapStateToProps),
  connect(null, mapDispatchToProps)
)(PlayChannel);
