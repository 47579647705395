import * as R from 'ramda'
import { GetTracks as Component } from './get-tracks'
import { connect } from 'react-redux'
import * as requests from '../../requests'
import * as tracks from '../../reducers/tracks'

function mapStateToProps(state, props) {
    return {
        ids: tracks.getQueryIds(state.tracks, props.id)
    }
}

function mapDispatchToProps(dispatch, props) {
    return {
        onFetch: () => {
            requests.fetchTracks(props.query,{
                success: (res) => {
                    const items = R.indexBy(R.prop('id'), res)
                    const trackIds = R.keys(items)
                    dispatch(tracks.updateTracks(items))
                    dispatch(tracks.updateQuery({
                        id: props.id,
                        ids: trackIds,
                    }))
                }
            })
            
        }
    }
}

export const GetTracks = R.compose(
    connect(mapStateToProps),
    connect(null, mapDispatchToProps)
)(Component)