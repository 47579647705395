import { AudioUploaderAndPlayer } from "../audio-uploader-and-player";
import { Box } from "../common/box";
import { EditorWrapper } from "../editor/editor-wrapper";
import SoundbankSelector from "../soundbank-selector";
import * as main from "../../reducers/main";

export function SamplesLibrary() {
  return (
    <EditorWrapper>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
      >
        <Box
          backgroundColor="white"
          borderRadius="3px"
          margin="3px"
          marginBottom="7px"
          style={{ flexGrow: 1 }}
        >
          <SoundbankSelector
            onFileSelection={(file, selected) => {
              main.playSound(file.id, window.rate);
            }}
            selected={null}
          />
        </Box>

        <Box
          backgroundColor="white"
          borderRadius="3px"
          margin="3px"
          padding="3px"
        >
          <AudioUploaderAndPlayer />
        </Box>
      </Box>
    </EditorWrapper>
  )
}
