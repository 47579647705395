import React from "react";
import * as R from "ramda";
import styled from "@emotion/styled/macro";
import * as editor from "../../reducers/editor";
import * as Redux from "react-redux";

const controls = {
  rate: {
    max: 1.5,
    min: 0.5,
    default: 1,
  },
  volume: {
    max: 1,
    min: 0.05,
    default: 1,
  },
};

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  margin: 5px;
  background: #80aaff linear-gradient(to right, #6b6b6d, #6b6b6d) no-repeat;
  background-size: 100% 10%;
`;

export function BarControl(props) {
  const element = React.useRef();
  const currentPatternId = Redux.useSelector(editor.getCurrentPatternId);
  //Todo (performance): Just getBarType instead of whole bar (if we don't need whole bar obj)
  const bar = Redux.useSelector((state) => {
    const patternId = editor.getCurrentPatternId(state);
    return editor.getBar(state.editor, patternId, props.channelId, props.id);
  });
  const dispatch = Redux.useDispatch();
  const controlValues = R.path([props.type], controls);
  const defaultValue = R.path([props.type, "default"], controls);
  const value = R.path([props.type], bar) || defaultValue;
  const min = controlValues.min;
  const max = controlValues.max;

  React.useEffect(() => {
    if (element.current) {
      const percentage = ((value - min) * 100) / (max - min);
      const reversedPercentage = 100 - percentage;
      element.current.style.backgroundSize = `100% ${reversedPercentage}%`;
    }
  }, [value, element, min, max]);

  React.useEffect(() => {
    if (element.current === props.selected.element) {
      const percentage =
        (props.selected.value * 100) / element.current.offsetHeight;
      //Maybe use snap points etc
      const result = max - (max - min) * (percentage / 100);
      const controlUpdate = R.assoc(props.type, result, {});
      dispatch(
        editor.updateBar(
          currentPatternId,
          props.channelId,
          props.id,
          controlUpdate
        )
      );
    }
  }, [value, element, props.selected, min, max, currentPatternId]);

  return (
    <Wrapper
      ref={element}
      style={{ opacity: value !== defaultValue ? 1 : 0.6 }}
    />
  );
}
