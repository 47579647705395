import styled from "@emotion/styled/macro";
import { variant } from "styled-system";
import { css } from "@styled-system/css";

export const Button = styled("button")(
  {
    appearance: "none",
    fontFamily: "inherit",
    cursor: "pointer",
    border: "none",
  },
  (props) =>
    props.absolute && {
      position: "absolute",
      top: props.absolute.top,
      right: props.absolute.right,
      left: props.absolute.left,
      bottom: props.absolute.bottom,
    },
  css({
    padding: "1",
    borderRadius: "0",
    fontSize: "3",
  }),
  variant({
    variants: {
      0: {
        background: "white",
        color: "#7d7878",
        fontSize: "17px",
        padding: "14px",
      },
      primary: {
        color: "buttonPrimaryText",
        bg: "buttonPrimaryBg",
        "&:hover": {
          bg: "buttonPrimaryHoverBg",
        },
      },
      secondary: {
        color: "buttonSecondaryText",
        bg: "buttonSecondaryText",
      },
    },
  })
);
