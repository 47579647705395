// Create an audio context
export const MAIN_AUDIO_CONTEXT = new AudioContext();

export let audioSources = {
  master: MAIN_AUDIO_CONTEXT.createGain(),
};
for (let i = 1; i <= 20; i++) {
  const newSource = MAIN_AUDIO_CONTEXT.createGain();
  newSource.connect(audioSources.master);
  audioSources[i.toString()] = newSource;
}

const compressor = MAIN_AUDIO_CONTEXT.createDynamicsCompressor();
compressor.threshold.setValueAtTime(-40, MAIN_AUDIO_CONTEXT.currentTime);
compressor.knee.setValueAtTime(30, MAIN_AUDIO_CONTEXT.currentTime);
compressor.ratio.setValueAtTime(4, MAIN_AUDIO_CONTEXT.currentTime);
compressor.attack.setValueAtTime(0.01, MAIN_AUDIO_CONTEXT.currentTime);
compressor.release.setValueAtTime(0.25, MAIN_AUDIO_CONTEXT.currentTime);

// audioSources["master"].connect(MAIN_AUDIO_CONTEXT.destination);

// Connect the master gain to the compressor
audioSources["master"].connect(compressor);
// And then connect the compressor to the AudioContext's destination
// compressor.connect(MAIN_AUDIO_CONTEXT.destination);

export function getAudioSource(effectsChannelId) {
  return audioSources[effectsChannelId.toLowerCase()];
}

// Sort master 1, 2, 3, 4, ...
export const audioSourcesKeys = Object.keys(audioSources).sort((a, b) => {
  if (a === "master") return -1;
  if (b === "master") return 1;
  return a - b;
});

// export function setNewAudioSourceOutput(effectsChannelId, destination) {
//   const audioSource = getAudioSource(effectsChannelId);
//   audioSources[effectsChannelId].disconnect();
//   audioSources[effectsChannelId].connect(destination);
// }

// Should be used to replace the previous one
// export function setNewAudioSourceOutput(effectsChannelId, destination) {
//   audioSources[effectsChannelId].disconnect();
//   audioSources[effectsChannelId].connect(destination);
// }
