import * as R from "ramda";

export const getAllSounds = (arr, fil = []) => {
  const files = fil;
  const folders = [];
  R.map((item) => {
    item.type == "folder" && folders.push(item.children);
    item.type == "file" && files.push(item);
  }, arr);

  if (folders.length > 0) {
    return getAllSounds(R.flatten(folders), files);
  } else {
    return files;
  }
};
