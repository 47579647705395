import { useEffect } from "react";

function shouldFetch(props) {
  if (props.query.type == "user" && props.query.id) {
    return true;
  }
  if (props.query.type == "latest") {
    return true;
  }
}

export function GetTracks(props) {
  useEffect(() => {
    if (shouldFetch(props)) {
      props.onFetch();
    }
  }, [props.query]);
  return props.children(props.ids);
}
