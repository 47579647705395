import * as R from 'ramda'
import * as constants from './constants'

const initialState = {
  items: {},
  queries: {}
}

export function reducer(state = initialState, action) {
    switch (action.type) {
      case constants.UPDATE_TRACKS:
        const items = R.merge(state.items,action.payload)
        return R.assocPath(['items'], items, state)
      case constants.UPDATE_QUERY:
        return R.assocPath(['queries',action.payload.id], action.payload.ids, state)
      default:
        return state
    }
  }
