import * as R from "ramda";

export function getChannels(state) {
  return state.track.channels.map((channel, channelId) => ({
    ...channel,
  }));
}

// Will this be used? In case of where
// patterns have different channels?
// export function getPatternsChannels(state, patternId) {
//   const pattern = getPattern(state, patternId);
//   return pattern?.channels;
// }

// This is probably bad for performance
// Option 1: we should set the data like this in the reducer directly
// Option 2: Caching the data? Or other solution
export function getChannel(state, channelId, patternId) {
  const channel = state.track.channels.find(
    (channel) => channel.id === channelId
  );
  return {
    ...channel,
    bars: getBars(state, patternId, channelId),
  };
}

export function getChannelEffectId(state, channelId) {
  const channel = getChannel(state, channelId);
  // Default to "0" which is master
  return channel.effectId || "0";
}

export function isTrackEditing(state) {
  return state.track.editing;
}

export function getTrackName(state) {
  return state.track.name;
}

export function getTrackId(state) {
  return R.path(["track", "id"], state);
}

export function getTrackUserId(state) {
  return R.path(["track", "userId"], state);
}

export function getTrack(state) {
  return state.track;
}

export function getTrackBpm(state) {
  return state?.track?.bpm
}

export function getBar(state, patternId, channelId, barId, playType) {
  return R.path(
    ["track", "patterns", patternId, "channels", channelId, "bars", barId],
    state
  );
}

export function getPattern(state, patternId) {
  return R.path(["track", "patterns", patternId], state);
}

export function getPatternName(state, patternId) {
  const pattern = getPattern(state, patternId);
  return R.pathOr("", ["name"], pattern);
}

export function getBars(state, patternId, channelId) {
  return state?.track?.patterns[patternId]?.channels.find(
    (channel) => channel?.channelId === channelId
  )?.bars;
}

// Modern approach: For Redux.useSelector
// Reasoning: Whole state is passed to the selector instead of state.editor

export function getTimeline(state) {
  const timeline = state?.editor?.track?.timeline;
  return {
    // We might want to save it in this
    // structure in Redux & server as well
    patterns: timeline?.map((pattern) => ({
      ...pattern,
      ...getPattern(state.editor, pattern.patternId),
    })),
  };
}

// Todo: Fix dynamically instead of 32
export const PATTERN_LOOP_LENGTH = 32;

export function getTimelineChannels(state) {
  const timeline = getTimeline(state);

  return (
    (timeline?.patterns || [])
      // ### Explanation ###
      // We can skip this filtering as it's more costly
      // performance wise than adding all channels
      // .filter(
      //   (pattern) =>
      //     currentBar >= pattern.startTime &&
      //     currentBar <= pattern.startTime + PATTERN_LOOP_LENGTH
      // )
      .flatMap((pattern) =>
        pattern?.channels.map((channel) => ({
          patternId: pattern.patternId,
          patternStartTime: pattern.startTime,
          ...channel,
        }))
      )
  );
}

export function getTimelineChannel(state, patternId, channelId) {
  const timeline = getTimeline(state);
  const channel = timeline?.patterns[patternId]?.channels[channelId];
  return getChannel(state.editor, channelId, patternId);
}

export function getTimelineLoopLength(state) {
  const timeline = getTimeline(state);

  const lastTimelinePattern = timeline.patterns.reduce(
    (maxObject, currentObject) => {
      if (
        !maxObject.startTime ||
        currentObject.startTime > maxObject.startTime
      ) {
        return currentObject;
      }
      return maxObject;
    },
    timeline.patterns[0] || { startTime: -Infinity }
  );

  // It's not reliable to count with channel length as it might be shorter
  // than loop length if the last bar is not filled
  // Todo we should put pattern.length
  const lastPattern = getPattern(state.editor, lastTimelinePattern.patternId);
  const lastPatternLength = lastPattern?.length || PATTERN_LOOP_LENGTH;
  return lastTimelinePattern.startTime + lastPatternLength;
}

export function getPatterns(state) {
  return state?.editor?.track?.patterns;
}

export function getCurrentPatternId(state) {
  return state?.editor?.currentPatternId;
}

export function getEffectsChannels(state) {
  return state?.editor?.track?.effects;
}

export function getEffectsChannel(state, effectsChannelId) {
  return (state?.editor?.track?.effects || [])[effectsChannelId];
}
