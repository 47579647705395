import React from "react";
import styled from "@emotion/styled/macro";

const ButtonWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 10px;
  cursor: pointer;

  border: 1px solid rgba(255, 255, 255, 0.5);
  color: rgba(255, 255, 255, 0.5);

  :hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

const ButtonInner = styled.div`
  padding: 6px;
`;

class Button extends React.Component {
  render() {
    return (
      <ButtonWrapper
        className={this.props.className}
        onClick={() => {
          this.props.onClick && this.props.onClick();
        }}
      >
        <ButtonInner>{this.props.children}</ButtonInner>
      </ButtonWrapper>
    );
  }
}

export default Button;
