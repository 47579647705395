import React, { useEffect } from "react";
import styled from "@emotion/styled/macro";
import { Editor } from "../editor";
import * as Hooks from "../../hooks";

const Wrapper = styled.div`
  width: 100%;
  ${(props) =>
    props.fixedTopBar &&
    `
        padding-top:50px;
    `}
`;

// This component is for embedding via iframe
// Most common case: Try out samples etc

export function EmbedPattern(props) {
  const [query] = Hooks.useQueryParams();

  useEffect(() => {
    if (query.trackId) {
      props.onFetchTrack(query.trackId);
    }
  }, [query.trackId]);

  useEffect(() => {
    props.onMount(query.trackId);
  }, []);

  return (
    <Wrapper>
      <Editor
        trackId={query.trackId}
        fixedTopBar={props.fixedTopBar}
        isEmbedded={true}
      />
    </Wrapper>
  );
}

EmbedPattern.defaultProps = {
  fixedTopBar: true,
};
