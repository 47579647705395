import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import * as editor from "../reducers/editor";
import { bindActionCreators } from "redux";
import styled from "@emotion/styled/macro";
import {
  CHANNEl_TYPE_RATE,
  CHANNEl_TYPE_VOLUME,
  BAR_PITCH_VALUES,
  BAR_VOLUME_VALUES,
} from "../constants";

const RateBarWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  height: 100px;
`;

const RateBar = styled.div`
  flex-grow: 1;

  &:hover,
  &[data-selected="true"] {
    background: rgba(0, 0, 0, 0.2);
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid white;
  }
`;

class Bar extends React.Component {
  constructor(props) {
    super(props);
    this.updateBar = this.updateBar.bind(this);
    this.state = {
      selected: false,
    };
  }

  updateBar(payload) {
    this.props.updateBar(
      this.props.patternId,
      this.props.channelId,
      this.props.id,
      payload
    );
  }

  hasRate(rate) {
    return rate === this.props.rate;
  }

  hasVolume(volume) {
    return volume === this.props.volume;
  }

  render() {
    if (this.props.type == CHANNEl_TYPE_RATE) {
      return (
        <RateBarWrapper className="bar">
          {R.map(
            (rate) => (
              <RateBar
                onClick={() => {
                  this.updateBar({ rate });
                }}
                data-selected={this.hasRate(rate)}
              />
            ),
            BAR_PITCH_VALUES
          )}
        </RateBarWrapper>
      );
    }

    /* Change the UI to fit volume changes */
    if (this.props.type == CHANNEl_TYPE_VOLUME) {
      return (
        <RateBarWrapper className="bar">
          {R.map(
            (volume) => (
              <RateBar
                onPointerDown={() => {
                  this.updateBar({ volume });
                }}
                data-selected={this.hasVolume(volume)}
              />
            ),
            BAR_VOLUME_VALUES
          )}
        </RateBarWrapper>
      );
    }

    return (
      <div
        className="bar"
        onClick={() => {
          this.updateBar({
            selected: !this.props.selected,
            regionName: this.props.regionName,
          });
        }}
        data-selected={this.props.selected}
        data-playing={this.props.isPlaying}
      ></div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const patternId = editor.getCurrentPatternId(state);
  const barState = editor.getBar(
    state.editor,
    patternId,
    ownProps.channelId,
    ownProps.id
  );
  return {
    patternId,
    selected:
      barState &&
      barState.selected &&
      barState.regionName === ownProps.regionName,
    rate: barState && barState.rate,
    volume: barState && barState.volume,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateBar: editor.updateBar,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Bar);
