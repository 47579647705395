import React, { useEffect } from "react";
import { connect } from "react-redux";
import Channel from "../channel";
import { isAuthenticated, playSound } from "../../reducers/main";
import * as editor from "../../reducers/editor";
import styled from "@emotion/styled/macro";
import * as R from "ramda";
import { Box } from "../common/box";
import { isPlaying } from "../../reducers/player";
import * as Hooks from "../../hooks";
import { AudioRecorder } from "./audio-recorder";
import * as Middlewares from "../../middlewares";
import * as Helpers from "../../helpers";
import { PlayIndicator } from "./play-indicator";
import { FaPlus } from "react-icons/fa";

const Wrapper = styled.div`
  position: relative;
  ${(props) =>
    props.fixed &&
    `
      padding-top:50px;
    `}
`;

const Channels = styled.div`
  border-top-right-radius: 3px;
`;

const keys = "qwertyuiop";

function _Editor(props) {
  const user = Hooks.useUser();
  const [startNewRecording, setStartNewRecording] = React.useState(false);
  const uploadAudio = Hooks.useUploadAudio();

  useEffect(() => {
    window.addEventListener("keydown", props.onKeyDown);
    return () => window.removeEventListener("keydown", props.onKeyDown);
  }, [props.channels]);

  return (
    <div>
      <Wrapper className="content" fixed={props.fixedTopBar}>
        {props.isPlaying && (
          <PlayIndicator
            animationStart="100px" // 100px: Witdh of the channel names
            animationEnd="calc(100% - 35px)" // 35px: Width of the channel settings (+)
          />
        )}

        <Channels
          className="channels"
          style={
            !props.fixedTopBar
              ? {
                  borderTopRightRadius: "3px",
                  borderTopLeftRadius: "3px",
                }
              : {}
          }
        >
          {props.channels.map((channel, i) => (
            <Channel
              key={i}
              id={i}
              isFixed={props.fixedTopBar}
              isEmbedded={props.isEmbedded}
            />
          ))}
        </Channels>
        {startNewRecording && (
          <div>
            <AudioRecorder
              onAcceptRecording={async (audioBlobUrl, audioBlob) => {
                setStartNewRecording(false);

                // Needed to be generated in the client so we don't have to wait for the server to respond with sound id (based on checksum)
                const soundId = await Helpers.generateSoundId(
                  audioBlobUrl,
                  user.id
                );
                props.loadSound(soundId, audioBlobUrl);
                props.addChannel("New recording", soundId);

                // Todo: Make track use this audio after a refresh
                // Not used yet!
                await Helpers.setLocalAudio(
                  Helpers.normalizeSoundName(audioBlobUrl),
                  audioBlob
                );

                const formData = new FormData();
                formData.append("audio", audioBlob, "New recording.mp3");
                formData.append("userId", user.id);
                await uploadAudio(formData);
              }}
            />
          </div>
        )}

        {props.addChannel && (
          <div
            className="add-channel"
            style={{
              background: "white",
              background: "white",
              display: "flex",
              ...(!props.fixedTopBar
                ? {
                    borderBottomRightRadius: "3px",
                    borderBottomLeftRadius: "3px",
                  }
                : {}),
            }}
          >
            {[
              {
                name: "Add channel",
                func: () => props.addChannel("name", "sound-id"),
              },
              {
                name: "Record new channel",
                func: () => setStartNewRecording(true),
              },
            ].map((item) => (
              <div
                onClick={item.func}
                style={{
                  positio: "relative",
                  color: "rgba(0, 0, 0, .3)",
                  fontSize: "13px",
                  cursor: "pointer",
                  opacity: ".6",
                  margin: "10px",
                  width: "80px",
                  textAlign: "center",
                  fontSize: "12px",
                  display: "flex",
                }}
              >
                <FaPlus color="inherit" sie="12px" />
                <Box marginLeft="3px">{item.name}</Box>
              </div>
            ))}
          </div>
        )}
      </Wrapper>
    </div>
  );
}

function mapStateToProps(state, props) {
  return {
    // Probably needs the following to know if pattern or timeline is playing
    // - playType
    // - patternId (to know what pattern) // Or we only always play the pattern that is currently selected in editor
    isPlaying: isPlaying(state.player, "editor", "pattern"),
    isAuthenticated: isAuthenticated(state.main),
    isEditing: props.isEditing || editor.isTrackEditing(state.editor),
    channels: editor.getChannels(state.editor),
  };
}

function mapDispatchToProps(dispatch, props) {
  return {
    onKeyDown: (e) => {
      const channelId = R.findIndex(R.equals(e.key), keys);
      if (channelId > -1) {
        const channel = props.channels[channelId];
        playSound(channel.soundId);
      }
    },
    addChannel: !props.isEmbedded
      ? (name, soundId) => {
          dispatch(editor.addChannel(name, soundId));
        }
      : undefined,
    loadSound: (soundId, blobUrl) => {
      dispatch(Middlewares.sound.loadSound(soundId, blobUrl));
    },
  };
}

_Editor.defaultProps = {
  isEditing: true,
};

export const Editor = R.compose(
  connect(mapStateToProps),
  connect(null, mapDispatchToProps)
)(_Editor);
