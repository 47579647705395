import React, { useState, useEffect, useCallback, useRef } from "react";
import * as editor from "../reducers/editor";
import { Box } from "./common/box";
import { useParams } from "react-router-dom";
import Peer from "peerjs";
import { QRCodeSVG } from "qrcode.react";
import { v4 as uuidv4 } from "uuid";
import * as Redux from "react-redux";
import { playSound } from "../reducers/main";

const padsHorizontal = 4;
const padsVertical = 4;

function Companion() {
  let padId = 0;
  const { id } = useParams();
  const [webRtcId] = useState(id || uuidv4());
  const isHost = !id;
  const webRtcEvent = useWebRtcEvent(webRtcId, isHost);
  const [channelId, setChannelId] = useState(0);
  const channelIdRef = useRef(channelId);
  const channel = Redux.useSelector((state) =>
    editor.getChannel(state.editor, channelId)
  );

  // Update the ref whenever channelId changes
  useEffect(() => {
    channelIdRef.current = channelId;
  }, [channelId]);

  useEffect(() => {
    const handleEvent = (event) => {
      console.log("WebRTC event:", event);
      if (event.type === "pad_clicked") {
        console.log("pad clicked event");
        console.log("- channel:", channel);
        console.log("- channelId:", channelIdRef.current);
        const soundId = channel?.soundId;
        const padNumber = event.padId;
        const regionName = padNumber.toString();
        playSound(soundId, undefined, undefined, regionName);
      }

      if (event.type === "set_channel_id") {
        setChannelId(Number(event.id));
      }
    };

    const unsubscribe = webRtcEvent.listen(handleEvent);

    return () => unsubscribe();
  }, [webRtcEvent, channel]);

  const qrCodeUrl = `${window.location.origin}/companion/${webRtcId}`;

  if (isHost) {
    return <QRCodeComponent url={qrCodeUrl} />;
  }

  return (
    <Box>
      {Array.from({ length: padsVertical }).map((_, rowIndex) => (
        <Box key={rowIndex} display="flex">
          {Array.from({ length: padsHorizontal }).map((_, colIndex) => (
            <Pad key={colIndex} id={padId++} webRtcEvent={webRtcEvent} />
          ))}
        </Box>
      ))}
      <SelectChannel webRtcEvent={webRtcEvent} />
    </Box>
  );
}

function SelectChannel({ webRtcEvent }) {
  const [channelId, setChannelId] = useState("0");
  const handleChange = (event) => {
    setChannelId(event.target.value);
  };

  React.useEffect(() => {
    if (webRtcEvent.connected) {
      webRtcEvent.send({ type: "set_channel_id", id: channelId });
    }
  }, [channelId]);

  return (
    <Box>
      <Box>Select channel:</Box>
      <Box>
        <select value={channelId} onChange={handleChange}>
          <option value="0">0</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10</option>
        </select>
      </Box>
    </Box>
  );
}

function QRCodeComponent({ url }) {
  return (
    <Box margin="20px" textAlign="center">
      <QRCodeSVG value={url} size={256} />
      <Box marginTop="10px" fontSize="13px">
        Scan with your mobile device
        <a href={url} target="_blank" rel="noopener noreferrer">
          {url}
        </a>
      </Box>
    </Box>
  );
}

function Pad({ id, webRtcEvent }) {
  const handleInteraction = (event) => {
    event.preventDefault();
    if (webRtcEvent.connected) {
      webRtcEvent.send({ type: "pad_clicked", padId: id });
    }
  };

  return (
    <Box
      onPointerDown={handleInteraction}
      width="20vh"
      height="20vh"
      margin="5px"
      backgroundColor="lightgray"
      cursor="pointer"
      fontSize="30px"
      color="#c8c8c8"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      {id}
    </Box>
  );
}

function useWebRtcEvent(id, isHost) {
  const [connection, setConnection] = useState(null);
  const [connected, setConnected] = useState(false);
  const [listeners] = useState([]);

  useEffect(() => {
    const newPeer = new Peer(isHost ? id : null);

    newPeer.on("open", (peerId) => {
      console.log("My peer ID is: " + peerId);

      if (!isHost) {
        const conn = newPeer.connect(id);
        conn.on("open", () => {
          setConnection(conn);
          setConnected(true);
          console.log("Connected to host");
        });
      }
    });

    newPeer.on("connection", (conn) => {
      setConnection(conn);
      setConnected(true);
    });

    return () => {
      if (newPeer) {
        newPeer.destroy();
      }
    };
  }, [id, isHost]);

  useEffect(() => {
    if (connection) {
      const handleData = (data) => {
        listeners.forEach((listener) => listener(data));
      };

      connection.on("data", handleData);
      connection.on("close", () => setConnected(false));

      return () => {
        connection.off("data", handleData);
        connection.off("close");
      };
    }
  }, [connection, listeners]);

  const send = useCallback(
    (event) => {
      if (connection && connected) {
        connection.send(event);
      }
    },
    [connection, connected]
  );

  const listen = useCallback(
    (callback) => {
      listeners.push(callback);
      return () => {
        const index = listeners.indexOf(callback);
        if (index > -1) {
          listeners.splice(index, 1);
        }
      };
    },
    [listeners]
  );

  return {
    connected,
    send,
    listen,
  };
}

export default Companion;
