export const pitchShifter = {
  name: "Pitch Shifter",
  moduleUrl: "/processors/pitchShifterProcessor.js",
  processorName: "pitch-shifter-processor",
  settings: {
    bufferSize: 1024,
    sampleRate: 44100,
  },
  parameters: {
    pitchShift: {
      value: 1.0, // 1.0 = no pitch shift, higher values increase pitch, lower values decrease pitch
      range: { min: 0.5, max: 2.0 },
    },
    mix: {
      value: 0.5, // 0.0 = full dry, 1.0 = full wet (pitch-shifted)
      range: { min: 0.0, max: 1.0 },
    },
  },
};
