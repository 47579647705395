import React from "react";
import styled from "@emotion/styled/macro";
import { keyframes } from "@emotion/core";
import * as Hooks from "../../hooks";

// Make a function that takes an argument with the data
// and then return it (send props when called from PlayIndicator)
const playIndicatorPositionAnimation = (
  animationStart,
  animationEnd
) => keyframes`
  0% {
    left:${animationStart};
  }
  100% {
    left:${animationEnd};
  }
`;

const _PlayIndicator = styled.div`
  top: 0px;
  animation: ${(props) =>
      playIndicatorPositionAnimation(props.animationStart, props.animationEnd)}
    ${(props) => props.animationDuration}s infinite linear;
  position: absolute;
  height: 100%;
  width: 1px;
  background-color: white;
`;

// Todo:
// To make this animation accurate over time,
// It should reset every time the loop resets
// Otherwise it will be out of phase with the play bars graphic change

// add start and end, ex:
// props.start = "100px" // 100px is the width of the channel names
// props.end = "100% - 35px" //35px is the width of the channel settings (+)
export function PlayIndicator(props) {
  // Todo: The hook below should return different data for playing timeline
  const trackLengthInSeconds = Hooks.useTrackLengthInSeconds();
  return (
    <_PlayIndicator
      animationDuration={trackLengthInSeconds}
      animationStart={props.animationStart}
      animationEnd={props.animationEnd}
    />
  );
}
