import * as React from "react";
import { EditorWrapper } from "../editor/editor-wrapper";
import { AudioVolumeDisplay } from "../../effects/AudioVolumeDisplay";

export function EffectsChannels() {
  return (
    <EditorWrapper>
      EffectsChannels
      <AudioVolumeDisplay name="Effects" />
      <AudioVolumeDisplay name="Master" />
      <AudioVolumeDisplay name="1" />
      <AudioVolumeDisplay name="2" />
      <AudioVolumeDisplay name="3" />
      <AudioVolumeDisplay name="4" />
      <AudioVolumeDisplay name="Howler" sourceNode={Howler.masterGain} />
    </EditorWrapper>
  );
}
