import * as React from 'react';
import * as Redux from 'react-redux';
import * as Hooks from '.';
import { clientConfig } from "../client-config";
import * as main from "../reducers/main";
import * as Middlewares from "../middlewares";
import * as R from 'ramda'
import { USER_STORAGE_KEY } from "../constants";

export function useAndFetchSoundbank() {
  // It's important that we have the user immediately available when loading the app (specially in the create-track view)
  const user = JSON.parse(localStorage.getItem(USER_STORAGE_KEY));
  const dispatch = Redux.useDispatch();
  const url = `${clientConfig.apiUrl}/api/postgres/get-user-audio/${user?.id}`;
  const soundBank = Hooks.useFetch(url, { method: "GET" }, null, true);
  const soundBankData = Redux.useSelector((state) =>
    main.getSoundbank(state.main)
  );

  React.useEffect(() => {
    if (soundBank.data) {
      dispatch(main.updateSoundbank(soundBank.data.sounds));
      dispatch(Middlewares.sound.loadSounds(soundBank.data.sounds));
    }
  }, [soundBank.data]);

  return {
    ...soundBank,
    data: R.isEmpty(soundBankData) ? null : soundBankData,
  };
}
