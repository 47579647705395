export const bitcrusher = {
  name: "Bitcrusher",
  description:
    "Reduces the bit depth and sample rate of the audio signal for a lo-fi effect.",
  moduleUrl: "/processors/bitcrusherProcessor.js",
  processorName: "bitcrusher-processor",
  settings: {
    bufferSize: 1024,
    sampleRate: 44100,
  },
  // How parameters work:
  // 1. Used to render UI controls.
  // 2. Get data that user selects or defaults.
  // 3. Add data to settings and provide to the processor.
  parameters: {
    bitDepth: {
      value: 8, // Default bit depth
      range: {
        min: 1,
        max: 16,
      },
    },
    reduction: {
      value: 1, // Default sample rate reduction
      range: {
        min: 1,
        max: 20,
      },
    },
  },
};
