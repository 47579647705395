export const bigEcho = {
  name: "Big Echo",
  moduleUrl: "/processors/bigEchoProcessor.js",
  processorName: "big-echo-processor",
  settings: {
    bufferSize: 1024,
    sampleRate: 44100,
  },
  // How parameters work:
  // 1. Use to render UI
  // 2. Get data that user selects or default
  // 3. Add data to settings and provide to worker
  parameters: {
    delayLength: {
      value: 0.5,
      range: {
        min: 0,
        max: 1,
      },
    },
    feedback: {
      value: 0.5,
      range: {
        min: 0,
        max: 1,
      },
    },
    mix: {
      value: 0.5,
      range: {
        min: 0,
        max: 1,
      },
    },
  },
};
