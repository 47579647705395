import * as React from "react";
import { getAudioSource } from "./audio-sources";

export const AudioVolumeDisplay = (props) => {
  const sourceNode = props.sourceNode || getAudioSource(props.name);
  const [volume, setVolume] = React.useState(0);

  React.useEffect(() => {
    if (sourceNode) {
      // Access the AudioContext from the sourceNode
      const audioContext = sourceNode.context;

      // Create an AnalyserNode
      const analyser = audioContext.createAnalyser();
      analyser.fftSize = 2048;
      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      // Connect the sourceNode to the analyser
      sourceNode.connect(analyser);

      let animationFrameId;

      // Function to compute and update the volume
      const updateVolume = () => {
        analyser.getByteTimeDomainData(dataArray);

        // Compute the volume from the audio data
        let sum = 0;
        for (let i = 0; i < bufferLength; i++) {
          const value = (dataArray[i] - 128) / 128;
          sum += value * value;
        }
        const rms = Math.sqrt(sum / bufferLength);
        setVolume(rms);

        animationFrameId = requestAnimationFrame(updateVolume);
      };

      // Start the repeating calls
      animationFrameId = requestAnimationFrame(updateVolume);

      // Cleanup
      return () => {
        cancelAnimationFrame(animationFrameId);
        sourceNode.disconnect(analyser);
      };
    }
  }, [sourceNode]);

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      {props.name} Volume:{" "}
      <div
        style={{
          marginLeft: "10px",
          height: "20px",
          width: `${(volume * 100).toFixed(2)}px`,
          backgroundColor: "#387aff",
        }}
      />
    </div>
  );
};
