import React from "react";
import styled from "@emotion/styled/macro";
import { Box } from "../common/box";
import { bg2 } from "../../global-styles";

const Wrapper = styled.div`
  width: 100%;
`;

// Todo: This component must be able to
// play both pattern and timeline
export function EditorWrapper(props) {
  return (
    <Box
      display="flex"
      flex="1"
      maxWidth="800px"
      padding="5px"
      backgroundColor={bg2}
      borderRadius="5px"
      boxShadow="0 5px 5px rgba(0,0,0,0.2)"
      minHeight="295px"
      // Important hack for the content to not make this frame grow
      minWidth="0"
    >
      <Box
        display="flex"
        flex="1"
        borderRadius="5px"
        // Important hack for the content to not make this frame grow
        minWidth="0"
      >
        <Wrapper>{props.children}</Wrapper>
      </Box>
    </Box>
  );
}
