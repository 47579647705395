import React from "react";
import SoundbankSelector from "./soundbank-selector";
import styled from "@emotion/styled/macro";
import { playSound } from "../reducers/main";
import * as editor from "../reducers/editor";
import * as player from "../reducers/player";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Bars from "./bars";
import { ChannelSettings } from "./channel-settings";
import { getAudioBlobUrl } from "../middlewares/sound-middleware";
import { FaMinus, FaPlus } from "react-icons/fa";
import { Box } from "./common/box";

const Wrapper = styled("div")`
  position: relative;
  transition: height 0.1s ease-out;
  height: 40px;

  ${(props) =>
    props.showBarControls &&
    `
    height:250px;
  `}
`;

const SelectSampleWrapper = styled("div")`
  position: absolute;
  top: 100%;
  left: 10px;
  background: white;
  z-index: 1;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  height: 200px;
`;

const ChannelName = styled("div")`
  ${(props) => (props.onClick ? "cursor: pointer;" : "pointer-events: none;")}
`;

const ChannelNameText = styled("div")`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 10px;
`;

const BarContainer = styled("div")`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

// function createBlobUrlFromHowlerSound(howlerSound) {
//   if (!howlerSound || typeof howlerSound.state !== "function") {
//     console.error("Invalid Howler sound object:", howlerSound);
//     return;
//   }

//   // Check if the sound is loaded
//   if (howlerSound.state() !== "loaded") {
//     console.error("Howler sound is not loaded yet");
//     return;
//   }

//   // Get the AudioBuffer
//   const audioBuffer = howlerSound._sounds[0]._node.bufferSource.buffer;

//   if (!audioBuffer) {
//     console.error("Unable to access audio data");
//     return;
//   }

//   const numberOfChannels = audioBuffer.numberOfChannels;
//   const length = audioBuffer.length;
//   const sampleRate = audioBuffer.sampleRate;
//   const audioData = new Float32Array(length * numberOfChannels);

//   for (let channel = 0; channel < numberOfChannels; channel++) {
//     const channelData = audioBuffer.getChannelData(channel);
//     for (let i = 0; i < length; i++) {
//       audioData[i * numberOfChannels + channel] = channelData[i];
//     }
//   }

//   const wavFile = new Blob(
//     [audioBufferToWav(audioData, numberOfChannels, sampleRate)],
//     { type: "audio/wav" }
//   );
//   return URL.createObjectURL(wavFile);
// }

// function audioBufferToWav(samples, numChannels, sampleRate) {
//   const buffer = new ArrayBuffer(44 + samples.length * 2);
//   const view = new DataView(buffer);

//   writeString(view, 0, "RIFF");
//   view.setUint32(4, 36 + samples.length * 2, true);
//   writeString(view, 8, "WAVE");
//   writeString(view, 12, "fmt ");
//   view.setUint32(16, 16, true);
//   view.setUint16(20, 1, true);
//   view.setUint16(22, numChannels, true);
//   view.setUint32(24, sampleRate, true);
//   view.setUint32(28, sampleRate * numChannels * 2, true);
//   view.setUint16(32, numChannels * 2, true);
//   view.setUint16(34, 16, true);
//   writeString(view, 36, "data");
//   view.setUint32(40, samples.length * 2, true);

//   floatTo16BitPCM(view, 44, samples);

//   return buffer;
// }

// function writeString(view, offset, string) {
//   for (let i = 0; i < string.length; i++) {
//     view.setUint8(offset + i, string.charCodeAt(i));
//   }
// }

// function floatTo16BitPCM(output, offset, input) {
//   for (let i = 0; i < input.length; i++, offset += 2) {
//     const s = Math.max(-1, Math.min(1, input[i]));
//     output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
//   }
// }

class Channel extends React.Component {
  constructor(props) {
    super(props);
    this.handleOnFileSelection = this.handleOnFileSelection.bind(this);
    this.toggleSettingsState = this.toggleSettingsState.bind(this);
    this.state = {
      showSettings: false,
      showBarControls: false,
    };
  }

  toggleSettingsState() {
    this.setState({
      showSettings: !this.state.showSettings,
    });
  }

  handleOnFileSelection(file, selected) {
    this.setState({
      selected,
      //showSettings: false,
    });
    this.props.updateChannel(this.props.state.id, file.name, file.id);
    playSound(file.id, window.rate);
  }

  toggleBarControls(showBarControls) {
    this.setState({
      showBarControls,
    });
  }

  render() {
    const blobUrl =
      this.props.state.soundId && getAudioBlobUrl(this.props.state.soundId);

    return (
      <Wrapper
        className="channel"
        file={this.state.path}
        type={this.props.state.type}
        showBarControls={this.state.showBarControls}
        //onPointerOver={() => { this.toggleBarControls(true) }}
        //onPointerLeave={() => { this.toggleBarControls(false) }}
      >
        <ChannelName
          className="head"
          onClick={!this.props.isEmbedded && this.toggleSettingsState}
        >
          <ChannelNameText title={this.props.state.name.split(".")[0]}>
            {this.props.state.name.split(".")[0]}
          </ChannelNameText>
        </ChannelName>

        <BarContainer>
          {(this.props.state.regions || [null]).map((region, i) => (
            <Bars
              channelId={this.props.state.id}
              currentBar={this.props.currentBar}
              regionName={region?.name}
            />
          ))}

          {this.state.showBarControls && (
            <ChannelSettings
              channelId={this.props.state.id}
              audioUrl={blobUrl}
              soundId={this.props.state.soundId}
            />
          )}
        </BarContainer>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="35px"
          cursor="pointer"
          bg="#6a6a6d"
          color="#c6c6c6"
          hover={{
            color: "white",
          }}
          boxShadow="inset 0px -1px rgba(255, 255, 255, 0.2)"
          onClick={() => this.toggleBarControls(!this.state.showBarControls)}
        >
          {this.state.showBarControls ? (
            <FaMinus size="12px" />
          ) : (
            <FaPlus size="12px" />
          )}
        </Box>

        {this.state.showSettings && (
          <SelectSampleWrapper>
            <SoundbankSelector
              onFileSelection={this.handleOnFileSelection}
              selected={this.state.selected}
            />
          </SelectSampleWrapper>
        )}
      </Wrapper>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    state: editor.getChannel(state.editor, ownProps.id),
    currentBar: player.getCurrentBar(state.player, "editor"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateChannel: editor.updateChannel,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Channel);
