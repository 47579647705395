import { clientConfig } from "../client-config";

export function useUploadAudio() {
// Todo: Fix so user is picked up here, then do:
// const formData = new FormData();
// formData.append("audio", audioBlob, "New recording.mp3");
// formData.append("userId", "52");

  return async (audioData) => {
    const response = await fetch(
      `${clientConfig.apiUrl}/api/postgres/upload-audio`,
      {
        method: "POST",
        body: audioData,
      }
    );
    return await response.json();
  }
}
