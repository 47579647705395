import * as R from "ramda";
import { CreatePattern as Component } from "./create-pattern";
import { connect } from "react-redux";
import * as editor from "../../reducers/editor";

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch, props) {
  return {
    onMount: () => {
      if (props.trackData) {
        dispatch(editor.updateTrack(props.trackData));
      }
    },
  };
}

export const CreatePattern = R.compose(
  connect(mapStateToProps, mapDispatchToProps)
)(Component);
