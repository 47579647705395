export const reverb = {
  name: "Reverb",
  moduleUrl: "/processors/reverbProcessor.js",
  processorName: "reverb-processor",
  settings: {
    bufferSize: 16384, // Increased from 1024 for longer tails
    sampleRate: 44100,
  },
  parameters: {
    reverbTime: {
      value: 2.0,
      range: {
        min: 0.1,
        max: 10.0, // Increased max value for longer reverbs
      },
    },
    decay: {
      value: 2.0,
      range: {
        min: 0.1,
        max: 10.0, // Increased max value
      },
    },
    mix: {
      value: 0.5,
      range: {
        min: 0.0,
        max: 1.0,
      },
    },
    preDelay: { // Added pre-delay parameter
      value: 0.03,
      range: {
        min: 0.0,
        max: 0.2,
      },
    },
    lowCut: { // Added low cut parameter
      value: 80,
      range: {
        min: 20,
        max: 1000,
      },
    },
    highCut: { // Added high cut parameter
      value: 8000,
      range: {
        min: 1000,
        max: 20000,
      },
    },
  },
};