import React from "react";
import { connect } from "react-redux";
import * as player from "../../reducers/player";
import * as editor from "../../reducers/editor";
import styled from "@emotion/styled/macro";
import { isAuthenticated, getUserId } from "../../reducers/main";
import { theme } from "../../global-styles";
import * as R from "ramda";
import { Flex } from "../common/flex";
import { FaPlay, FaPause } from "react-icons/fa";
import { Box } from "../common/box";

const TopbarWrapper = styled.header`
  ${(props) =>
    props.fixed &&
    `
        position:fixed;
        top:0px;
        left:0px;
    `}
  padding: 0 12px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  z-index: 2;
`;

const PatternNameInput = styled.input`
  border: 0;
  height: 100%;
  background: transparent;
  font-size: 20px;
  color: ${theme.topbarLight};
  margin-left: 5px;
`;

class TopBar extends React.Component {
  constructor(props) {
    super(props);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.PatternNameInput = React.createRef();
    window.addEventListener("keydown", this.onKeyDown);
  }

  componentDidMount() {
    this.setPatternNameInputValue(this.props.patternName);
  }

  componentWillUpdate(newProps) {
    if (newProps.patternName != this.props.patternName) {
      this.setPatternNameInputValue(newProps.patternName);
    }
  }

  setPatternNameInputValue(value) {
    if (this.PatternNameInput.current) {
      this.PatternNameInput.current.value = value;
    }
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.onKeyDown);
  }

  onKeyDown(e) {
    const isPressingSpace = e.keyCode == 32;
    const isNotInTextField = e.target == document.body;
    if (isPressingSpace && isNotInTextField) {
      e.preventDefault();
      this.props.onTogglePlay();
    }
  }

  render() {
    return (
      <TopbarWrapper fixed={this.props.fixed}>
        <Flex alignItems="center">
          <Box
            cursor="pointer"
            onClick={() => this.props.onTogglePlay()}
            width="45px"
            height="45px"
            marginBottom="3px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            marginLeft="-12px"
            borderRadius="3px"
            hover={{
              backgroundColor: "rgba(255, 255, 255, 0.05)",
            }}
          >
            {this.props.isPlaying ? (
              <FaPause color="#bbbaba" size="20px" />
            ) : (
              <FaPlay color="#bbbaba" size="20px" />
            )}
          </Box>

          <Box>
            {this.props.isEmbedded ? (
              <PatternNameInput
                value="Try out the samples here!"
                style={{
                  pointerEvents: "none",
                  minWidth: "225px", // This is probably not the best thing to have static
                }}
              />
            ) : (
              <PatternNameInput
                type="text"
                placeholder={
                  this.props.playType === "pattern"
                    ? "Enter pattern name"
                    : "Enter track name"
                }
                onKeyUp={this.props.onNameChange}
                innerRef={this.PatternNameInput}
              />
            )}
          </Box>
        </Flex>
        {!this.props.isEmbedded && this.props.buttons && (
          <Flex alignContent="center">{this.props.buttons}</Flex>
        )}
      </TopbarWrapper>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    isEditing: props.isEditing || editor.isTrackEditing(state.editor),
    patternName:
      props.playType === "pattern"
        ? editor.getPatternName(state.editor, props.patternId || 0)
        : editor.getTrackName(state.editor),
    trackId: editor.getTrackId(state.editor),
    isPlaying: player.isPlaying(state.player, "editor", props.playType),
    isAuthenticated: isAuthenticated(state.main),
    userId: getUserId(state.main),
    trackUserId: editor.getTrackUserId(state.editor),
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    onTogglePlay: (bool) =>
      dispatch(player.togglePlay(bool, "editor", props.playType)),
    onNameChange: (event) => {
      dispatch(
        props.playType === "pattern"
          ? editor.updatePattern(props.patternId, { name: event.target.value })
          : editor.updateTrack({ name: event.target.value })
      );
    },
  };
};

export default R.compose(
  connect(mapStateToProps),
  connect(null, mapDispatchToProps)
)(TopBar);
