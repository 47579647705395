import * as React from "react";

/* Problem: It needs to ask for permission before rendering the possible audio sources */

export const AudioRecorder = (props) => {
  const [microphones, setMicrophones] = React.useState([]);
  const [selectedMic, setSelectedMic] = React.useState("");
  const [isRecording, setIsRecording] = React.useState(false);
  const [audioBlob, setAudioBlob] = React.useState(null);
  const audioContext = React.useRef(null);
  const analyser = React.useRef(null);
  const mediaRecorder = React.useRef(null);
  const audioChunks = React.useRef([]);

  React.useEffect(() => {
    // Request access to the microphone
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(() => {
        // Once access is granted, enumerate devices
        navigator.mediaDevices.enumerateDevices().then((devices) => {
          const mics = devices.filter((device) => device.kind === "audioinput");
          setMicrophones(mics);
          if (mics.length > 0) {
            setSelectedMic(mics[0].deviceId); // Default to the first mic
          }
        });
      })
      .catch((error) => {
        console.error("Error accessing media devices:", error);
      });

    return () => {
      // Clean up resources on unmount
      if (audioContext.current) {
        audioContext.current.close();
      }
    };
  }, []);

  const startRecording = async () => {
    try {
      if (!audioContext.current) {
        audioContext.current = new (window.AudioContext ||
          window.webkitAudioContext)();
        analyser.current = audioContext.current.createAnalyser();
        analyser.current.fftSize = 2048;
      }

      const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          deviceId: selectedMic ? { exact: selectedMic } : undefined,
          sampleRate: 48000,
          channelCount: 2,
          echoCancellation: true,
          noiseSuppression: false,
        },
      });

      const source = audioContext.current.createMediaStreamSource(stream);
      source.connect(analyser.current);

      const options = {
        mimeType: "audio/webm;codecs=opus",
        bitsPerSecond: 256000,
      };
      mediaRecorder.current = new MediaRecorder(stream, options);
      mediaRecorder.current.ondataavailable = (event) => {
        audioChunks.current.push(event.data);
      };

      mediaRecorder.current.onstop = () => {
        const blob = new Blob(audioChunks.current, { type: "audio/webm" });
        setAudioBlob(blob);
        audioChunks.current = [];
      };

      mediaRecorder.current.start();
      setIsRecording(true);
    } catch (error) {
      console.error("Error starting recording:", error);
    }
  };

  const stopRecording = () => {
    mediaRecorder.current.stop();
    setIsRecording(false);
  };

  const playRecording = () => {
    if (audioBlob) {
      const audioUrl = URL.createObjectURL(audioBlob);
      new Audio(audioUrl).play();
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <div
        style={{
          minWidth: "100px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          color: "white",
          fontSize: "13px",
        }}
      >
        New recording
      </div>
      <div>
        <WaveformVisualizer
          analyser={analyser.current}
          isRecording={isRecording}
        />
        <select
          onChange={(e) => setSelectedMic(e.target.value)}
          value={selectedMic}
        >
          {microphones.map((mic, index) => (
            <option key={mic.deviceId} value={mic.deviceId}>
              {mic.label || `Microphone ${index + 1}`}
            </option>
          ))}
        </select>
        <button onClick={isRecording ? stopRecording : startRecording}>
          {isRecording ? "Stop" : "Record"}
        </button>
        {audioBlob && <button onClick={playRecording}>Play Recording</button>}
        {audioBlob && (
          <button
            onClick={async () =>
              props.onAcceptRecording(URL.createObjectURL(audioBlob), audioBlob)
            }
          >
            Accept recording
          </button>
        )}
      </div>
    </div>
  );
};

const WaveformVisualizer = ({ analyser, isRecording }) => {
  const canvasRef = React.useRef(null);
  const animationFrameId = React.useRef(null);
  const drawPosition = React.useRef(0);

  React.useEffect(() => {
    const draw = () => {
      if (!canvasRef.current || !analyser) return;

      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      const width = canvas.width;
      const height = canvas.height;
      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      analyser.getByteTimeDomainData(dataArray);

      if (drawPosition.current >= width) {
        drawPosition.current = 0;
        ctx.clearRect(0, 0, width, height);
      }

      for (let i = 0; i < bufferLength; i++) {
        let v = dataArray[i] / 128.0;
        let y = (v * height) / 2;

        ctx.fillStyle = "rgb(0, 0, 0)";
        ctx.fillRect(drawPosition.current, y, 1, 1);
      }

      drawPosition.current += 1;
      animationFrameId.current = requestAnimationFrame(draw);
    };

    if (isRecording) {
      draw();
    }

    return () => cancelAnimationFrame(animationFrameId.current);
  }, [isRecording, analyser]);

  return (
    <canvas
      ref={canvasRef}
      width="600"
      height="100"
      style={{ border: "1px solid black" }}
    ></canvas>
  );
};
