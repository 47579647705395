import React, { useState, useEffect } from "react";
import * as Hooks from "../hooks";

export function AudioUploaderAndPlayer() {
  const [uploading, setUploading] = useState(false);
  const fetchAndUpdateSoundBank = Hooks.useAndFetchSoundbank();
  const uploadAudio = Hooks.useUploadAudio();
  const user = Hooks.useUser();
  const userId = user?.id;

  const handleUpload = async (file) => {
    setUploading(true);
    const formData = new FormData();
    formData.append("userId", userId);
    formData.append("audio", file);

    try {
      const response = await uploadAudio(formData);
      const data = await response.json();
      if (data.checksum) {
        // Upload successful
        fetchAndUpdateSoundBank.fetch();
      } else {
        alert("Upload failed. Please try again.");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      alert(
        "Error uploading file. Please check the console for more information."
      );
    } finally {
      setUploading(false);
    }
  };

  const onChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      handleUpload(file);
    }
  };

  if (!userId) {
    return <div>Login to upload your samples</div>;
  }

  return (
    <div>
      <div
        style={{
          border: "2px dashed #ccc",
          padding: 20,
          cursor: "pointer",
          textAlign: "center",
        }}
      >
        {uploading ? (
          <p>Uploading...</p>
        ) : (
          <p>Drag and drop your audio file here, or click to select a file</p>
        )}
        <input
          type="file"
          onChange={onChange}
          style={{ display: "none" }}
          id="fileInput"
        />
        <label htmlFor="fileInput" style={{ cursor: "pointer" }}>
          Click to select a file
        </label>
      </div>
    </div>
  );
}
