import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import {
  updateTrack,
  togglePlay,
  isPlaying,
  getTrackId,
} from "../reducers/main";
import styled from "@emotion/styled/macro";
import { TrackTile } from "./track-tile";
import * as editor from "../reducers/editor";
import * as player from "../reducers/player";
import { Text } from "./common/text";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const GridWrapper = styled.ul`
  display: flex;
  justify-content: center;
`;

const Title = styled.div`
  margin-top: 30px;
  margin-bottom: 10px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
`;

const GridItem = styled.div``;

function _TrackTileList(props) {
  return (
    <Wrapper>
      {props.title && (
        <Title>
          <Text
            fontSize="4"
            fontWeight="bold"
            textAlign="center"
            color="headerText"
          >
            {props.title}
          </Text>
        </Title>
      )}
      <GridWrapper>
        <Grid>
          {R.map(
            (trackId) => (
              <GridItem>
                <TrackTile
                  key={trackId}
                  trackId={trackId}
                  isPlaying={props.isTrackPlaying(trackId)}
                  onPlay={props.onPlay}
                  onEdit={props.onEdit}
                  editText={props.editText}
                />
              </GridItem>
            ),
            props.trackIds
          )}
        </Grid>
      </GridWrapper>
    </Wrapper>
  );
}

const mapStateToProps = (state) => {
  return {
    currentTrackId: getTrackId(state.main),
    isPlaying: isPlaying(state.main),
    isTrackPlaying: (trackId) => player.isPlaying(state.player, trackId),
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    onPlay: (track) => {
      const isPlaying = props.isTrackPlaying(track.id);
      dispatch(updateTrack(R.mergeDeepLeft({ editing: false }, track)));
      dispatch(player.togglePlay(!isPlaying, track.id));
    },
    onEdit: (track) => dispatch(editor.updateTrack(track)),
  };
};

export const TrackTileList = R.compose(
  connect(mapStateToProps),
  connect(null, mapDispatchToProps)
)(_TrackTileList);
