import * as R from "ramda";
import { EmbedPattern as Component } from "./embed-pattern";
import { connect } from "react-redux";
import { fetchTrack } from "../../requests";
import * as editor from "../../reducers/editor";

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch, props) {
  return {
    onMount: () => {
      if (props.trackData) {
        dispatch(editor.updateTrack(props.trackData));
      }
    },
    onFetchTrack: (trackId) => {
      fetchTrack(trackId, {
        success: (res) => {
          dispatch(editor.updateTrack(res));
        },
        fail: (res) => {},
      });
    },
  };
}

export const EmbedPattern = R.compose(
  connect(mapStateToProps, mapDispatchToProps)
)(Component);
