import * as React from 'react'
import * as Redux from 'react-redux'
import * as editor from "../../reducers/editor";

export function BpmInput() {
  const track = Redux.useSelector((state) => editor.getTrack(state.editor));
  const dispatch = Redux.useDispatch();

  const handleChange = (event) => {
    const newValue = parseInt(event.target.value, 10);
    dispatch(editor.updateTrack({ bpm: newValue }));
  };

  return (
    <input
      type="number"
      value={track.bpm}
      onChange={handleChange}
    />
  );
}
