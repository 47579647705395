import React, { useCallback, useEffect, useRef, useState } from "react";
import { WaveSurfer, WaveForm, Region } from "wavesurfer-react";
import * as editor from "../../reducers/editor";
import * as Middlewares from "../../middlewares";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as R from 'ramda'

import RegionsPlugin from "wavesurfer.js/dist/plugins/regions";
import ZoomPlugin from "wavesurfer.js/dist/plugins/zoom.js";


const PEAK_DETECTION = {
  THRESHOLD: 0.2,
  MIN_DURATION: 1,
  MAX_REGIONS: 100,
  PEAKS_COUNT: 1000,
  SILENCE_THRESHOLD: 0.05,
};

const plugins = [
  {
     plugin: RegionsPlugin,
     key: "regions",
     options: { dragSelection: true }
  },
  {
     plugin: ZoomPlugin,
     key: "zoom",
     options: {
       scale: 0.01,
       // maxZoom: 100
     }
  },
];

// Only return unique regions
function uniqueByRegion(regions) {
  return R.uniqBy(R.pick(['start','end']), regions);
}

const _SampleSlicer = (props) => {
  const wrapperRef = useRef();
  const [regions, setRegions] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [playingRegionId, setPlayingRegionId] = useState(null);
  const wavesurferRef = useRef();

  useEffect(() => {
    // Needed to update
    if (props?.state?.regions && props?.state?.regions?.length > 0) {
      setRegions(
        props.state.regions.map((region, i) => ({
          id: `region-${i}`,
          start: region.start / 1000,
          end: region.end / 1000,
          color: `rgba(255, 0, 0, 0.5)`,
          data: { systemRegionId: -1 },
        }))
      );
    }
  }, []);

  useEffect(() => {
    if (regions && regions.length > 0) {
      const formattedRegions = regions.map((region, i) => ({
        name: `${i}`,
        start: region.start * 1000,
        end: region.end * 1000,
      }));

      // Update Regions in Redux
      props.updateChannel(
        props.channelId,
        props.state.name,
        props.soundId,
        formattedRegions
      );

      // Update howler regions for sampler
      props.addregions(props.soundId, formattedRegions);
    }
  }, [regions]);

  const regionCreatedHandler = useCallback(
    (region) => {
      if (region?.data?.systemRegionId) return;
      if (!regions.some((r) => r.id === region.id)) {
        setRegions((prevRegions) => uniqueByRegion([
          ...prevRegions,
          { ...region, data: { ...region?.data, systemRegionId: -1 } },
        ]));
      }
    },
    [regions]
  );

  const handleWSMount = useCallback(
    (waveSurfer) => {
      wavesurferRef.current = waveSurfer;
      if (wavesurferRef.current) {
        wavesurferRef.current.load(props.audioUrl);
        wavesurferRef.current.on("ready", () => {
          setIsLoaded(true);
        });

        // Note: This seems to be the easiest way to get regions plugin
        const registeredRegionsPlugin = wavesurferRef.current.plugins[0]
        registeredRegionsPlugin.on("region-created", regionCreatedHandler);
        registeredRegionsPlugin.enableDragSelection({
          color: 'rgba(255, 0, 0, 0.1)',
        })
      }
    },
    [regionCreatedHandler, props.audioUrl]
  );

  const handleRegionUpdate = useCallback((region) => {
    setRegions((prevRegions) => {
      return prevRegions.map((r) => (r.id === region.id ? region : r));
    });
  }, [])

  const detectPeaks = useCallback(() => {
    const duration = wavesurferRef.current.getDuration();
    const peaks = wavesurferRef.current.backend.getPeaks(
      PEAK_DETECTION.PEAKS_COUNT
    );
    const peakInterval = duration / peaks.length;
    const regionsToAdd = [];
    let regionStart = null;

    for (let i = 0; i < peaks.length; i++) {
      if (
        Math.abs(peaks[i]) > PEAK_DETECTION.THRESHOLD &&
        regionStart === null
      ) {
        regionStart = i * peakInterval;
      } else if (
        regionStart !== null &&
        (Math.abs(peaks[i]) <= PEAK_DETECTION.SILENCE_THRESHOLD ||
          i === peaks.length - 1)
      ) {
        const end = i === peaks.length - 1 ? duration : i * peakInterval;
        regionsToAdd.push({
          id: `region-${regionsToAdd.length}`,
          start: regionStart,
          end: end,
          color: `rgba(255, 0, 0, 0.5)`,
          data: { systemRegionId: -1 },
        });
        regionStart = null;
      }
    }

    setRegions((prevRegions) => [...prevRegions, ...regionsToAdd]);
  }, [wavesurferRef]);

  const togglePlayRegion = useCallback(
    (regionId, start, end) => {
      if (playingRegionId === regionId) {
        wavesurferRef.current.pause();
        setPlayingRegionId(null);
      } else {
        wavesurferRef.current.play(start, end);
        setPlayingRegionId(regionId);
      }
    },
    [playingRegionId]
  );


  return (
    <div ref={wrapperRef}>
      <div style={{
        overflow:'hidden',
        maxWidth: `${wrapperRef?.current?.getBoundingClientRect().width}px`
      }}>
        <WaveSurfer plugins={plugins} onMount={handleWSMount} container="#waveform">
          <WaveForm id="waveform">
            {isLoaded && regions.map((regionProps) => (
              <Region
                {...regionProps}
                key={regionProps.id}
                onUpdateEnd={handleRegionUpdate}
              />
            ))}
          </WaveForm>
        </WaveSurfer>
      </div>
      <button onClick={detectPeaks}>Detect Peaks</button>
      {regions.map((region, index) => (
        <button
          key={region.id}
          onClick={() => togglePlayRegion(region.id, region.start, region.end)}
        >
          {playingRegionId === region.id ? "Stop" : `Play region ${index + 1}`}
        </button>
      ))}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    state: editor.getChannel(state.editor, ownProps.channelId),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateChannel: editor.updateChannel,
      addregions: Middlewares.sound.addregions,
    },
    dispatch
  );
};

export const SampleSlicer = connect(
  mapStateToProps,
  mapDispatchToProps
)(_SampleSlicer);
