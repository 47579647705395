import { useGoogleLogin } from '@react-oauth/google';
import React from "react";
import * as Hooks from "../../hooks";
import { Button } from "../common/button";
import * as editor from "../../reducers/editor";
import * as Redux from "react-redux";
import * as requests from "../../requests";
import { push } from "react-router-redux";
import { BpmInput } from "./bpm-input";
import { useOnGoogleLoginSuccess } from '../login';

// const login = useGoogleLogin({
//   onSuccess: codeResponse => console.log(codeResponse),
//   flow: 'auth-code',
// });

// This is just a placeholder for now
export function Header(props) {
  const dispatch = Redux.useDispatch();
  const user = Hooks.useUser();

  // Maybe make this into one hook
  const onGoogleLoginSuccess = useOnGoogleLoginSuccess()
  const login = useGoogleLogin({
    onSuccess: onGoogleLoginSuccess,
    flow: 'auth-code',
  });

  const onSaveTrack = React.useCallback(() => {
    const notYourTrack = props.trackUserId && props.userId != props.trackUserId;
    let trackId = notYourTrack ? uuid() : props.trackId;

    if (notYourTrack) {
      dispatch(
        editor.updateTrack({
          id: trackId,
          userId: props.userId,
        })
      );

      requests.saveTrack({
        originalTrackId: props.trackId,
      });
    } else {
      requests.saveTrack();
    }

    dispatch(push(`/create?trackId=${trackId}`));
  }, [props.trackId]);

  return (
    <div
      style={{
        height: "60px",
        width: "100%",
        backgroundColor: "black",
      }}
    >
      {user ?
        (<Button onClick={() => onSaveTrack()}>Save</Button>) :
        (<Button onClick={() => login()}>Login to save</Button>)
      }
      <BpmInput />
    </div>
  );
}
