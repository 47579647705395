import { useDebounce } from "@react-hook/debounce";
import * as React from "react";

export function useEffectDebounced(callback, dependencies, debounceTimeMs = 0) {
  const [debouncedDependencies, setDebouncedDependencies] = useDebounce(
    dependencies,
    debounceTimeMs
  );

  const prevDependenciesRef = React.useRef(dependencies);

  React.useEffect(() => {
    const areDependenciesEqual = prevDependenciesRef.current.every(
      (dep, index) => dep === dependencies[index]
    );

    if (!areDependenciesEqual) {
      setDebouncedDependencies(dependencies);
      prevDependenciesRef.current = dependencies;
    }
  }, [dependencies]);

  React.useEffect(callback, debouncedDependencies);
}
