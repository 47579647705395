import React from 'react'

export function Logo() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="70" height="70" viewBox="0 0 302.000000 296.000000" preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,296.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                <path d="M742 2198 c-402 -402 -600 -606 -613 -633 -24 -51 -24 -119 0 -170   13 -27 211 -231 613 -633 326 -325 597 -592 601 -592 4 0 7 125 7 278 l1 277   -381 378 -381 377 381 378 381 377 -1 278 c0 152 -3 277 -7 277 -4 0 -275   -267 -601 -592z" fill="white"></path>
                <path d="M1550 1480 l0 -1305 591 590 c399 399 597 603 610 630 24 51 24 119   0 170 -13 27 -211 231 -610 630 l-591 590 0 -1305z" fill="white"></path>
                <path d="M1110 1715 l-235 -235 238 -238 237 -237 0 473 c0 259 -1 472 -3 472   -1 0 -108 -106 -237 -235z" fill="white"></path>
            </g>
        </svg>
    )
}