import * as R from "ramda";
import { normalizeSoundName } from "../helpers";
import * as Middlewares from "../middlewares";

const TOGGLE_PLAY = "TOGGLE_PLAY";
const UPDATE_SOUNDBANK = "UPDATE_SOUNDBANK";
const ADD_CHANNEL = "ADD_CHANNEL";
const UPDATE_CURRENT_BAR = "UPDATE_CURRENT_BAR";
const RESET_CURRENT_BAR = "RESET_CURRENT_BAR";
const UPDATE_CHANNEL = "UPDATE_CHANNEL";
const UPDATE_BAR = "UPDATE_BAR";
const UPDATE_CHANNELS = "UPDATE_CHANNELS";
const UPDATE_USER = "UPDATE_USER";
const CLEAR_USER = "CLEAR_USER";
export const UPDATE_TRACK = "UPDATE_TRACK";
const CLEAR_TRACK = "CLEAR_TRACK";
const STORE_ALL_TRACKS = "STORE_ALL_TRACKS";

const initialState = {
  playing: false,
  soundbank: {},
  track: {
    name: "",
    editing: false,
    channels: [],
  },
  currentBar: -1,
  user: null,
};

export function playSound(soundName, rate, volume, regionName) {
  const sound = normalizeSoundName(soundName);
  return Middlewares.sound.play(sound, volume, rate, regionName);
}

export function togglePlay(payload) {
  return {
    type: TOGGLE_PLAY,
    payload,
  };
}

export function updateSoundbank(soundbank) {
  return {
    type: UPDATE_SOUNDBANK,
    payload: soundbank,
  };
}

// This even used anymore?
export function addChannel(name, soundId, type) {
  return {
    type: ADD_CHANNEL,
    channel: {
      name,
      soundId,
      bars: [],
      type,
    },
  };
}

export function updateChannel(id, name, soundId) {
  return {
    type: UPDATE_CHANNEL,
    channel: {
      id,
      name,
      soundId,
    },
  };
}

export function updateCurrentBar(currentBar) {
  return {
    type: UPDATE_CURRENT_BAR,
    currentBar,
  };
}

export function resetCurrentBar() {
  return {
    type: RESET_CURRENT_BAR,
  };
}

export function updateBar(channelId, barId, payload) {
  return {
    type: UPDATE_BAR,
    barId,
    channelId,
    payload,
  };
}

export function updateChannels(channels) {
  return {
    type: UPDATE_CHANNELS,
    channels,
  };
}

export function updateUser(user) {
  return {
    type: UPDATE_USER,
    user,
  };
}

export function clearUser() {
  return {
    type: CLEAR_USER,
  };
}

export function clearTrack() {
  return {
    type: CLEAR_TRACK,
  };
}

export function updateTrack(track) {
  return {
    type: UPDATE_TRACK,
    track,
  };
}

export function storeAllTracks(tracks) {
  return {
    type: STORE_ALL_TRACKS,
    tracks,
  };
}

/* Selectors */
export function getSoundbank(state) {
  return state.soundbank;
}

export function isPlaying(state) {
  return state.playing;
}

export function getCurrentBar(state) {
  return state.currentBar;
}

export function getBar(state, patternId, channelId, barId) {
  // Do we need to fix patternId here?
  // What exactly is this reducer used for?
  return state.track.channels[channelId].bars[barId];
}

export function getUser(state) {
  return state.user;
}

export function getUserId(state) {
  return state?.user?.id;
}

export function getUserTracks(state) {
  return state?.user?.tracks;
}

export function isAuthenticated(state) {
  return !R.isNil(state?.user?.id);
}

/* Track - Make new reducer for track actions/selectors */

export function getChannels(state) {
  return state.track.channels;
}

export function getChannel(state, id) {
  return R.filter(R.propEq("id", id), state.track.channels)[0];
}

export function isTrackEditing(state) {
  return state.track.editing;
}

export function getTrackName(state) {
  return state.track.name;
}

export function getTrackId(state) {
  return R.path(["track", "id"], state);
}

export function getTrack(state) {
  return state.track;
}

export function getAllTracks(state) {
  return state.allTracks;
}

const main = (state = initialState, action) => {
  const mergeWithState = R.merge(state);

  switch (action.type) {
    case TOGGLE_PLAY:
      return mergeWithState({ playing: action.payload || !state.playing });
    case UPDATE_SOUNDBANK:
      return mergeWithState({ soundbank: action.payload });
    case UPDATE_CHANNELS:
      return mergeWithState({ track: { channels: action.channels } });
    case ADD_CHANNEL:
      action.channel.id = state.track.channels.length;
      return mergeWithState({
        track: R.merge(state.track, {
          channels: state.track.channels.concat(action.channel),
        }),
      });
    case UPDATE_CHANNEL:
      const updateIndex = R.findIndex(R.propEq("id", action.channel.id))(
        state.track.channels
      );
      const updatedChannels = state.track.channels;
      updatedChannels[updateIndex] = R.merge(
        updatedChannels[updateIndex],
        action.channel
      );
      return R.mergeDeepLeft(state, { track: { channels: updatedChannels } });
    case UPDATE_CURRENT_BAR:
      return mergeWithState({ currentBar: action.currentBar });
    case RESET_CURRENT_BAR:
      return mergeWithState({ currentBar: initialState.currentBar });
    case UPDATE_BAR:
      const channelIndex = R.findIndex(R.propEq("id", action.channelId))(
        state.track.channels
      );
      const channels = state.track.channels;
      const rate = R.path(["payload", "rate"], action);
      const volume = R.path(["payload", "volume"], action);
      const existingBar = channels[channelIndex].bars[action.barId];
      channels[channelIndex].bars[action.barId] = existingBar || {};

      if (!R.isNil(action.payload.selected)) {
        channels[channelIndex].bars[action.barId].selected =
          action.payload.selected;
      }

      if (rate) {
        channels[channelIndex].bars[action.barId].rate = rate;
      }

      if (volume) {
        channels[channelIndex].bars[action.barId].volume = volume;
      }

      return R.mergeDeepLeft(state, { track: { channels } });
    case UPDATE_USER:
      return mergeWithState({ user: action.user });
    case CLEAR_USER:
      return mergeWithState({ user: initialState.user });
    case UPDATE_TRACK:
      return mergeWithState({
        track: R.merge(state.track, action.track),
      });
    case STORE_ALL_TRACKS:
      return mergeWithState({
        allTracks: action.tracks,
      });
    case CLEAR_TRACK:
      return mergeWithState({ track: initialState.track });
    default:
      return state;
  }
};
export default main;
