import * as constants from "./constants";

export function togglePlay(playing, id, playType) {
  return {
    type: constants.TOGGLE_PLAY,
    payload: {
      playing,
      id,
      playType,
    },
  };
}

export function updateCurrentBar(currentBar) {
  return {
    type: constants.UPDATE_CURRENT_BAR,
    currentBar,
  };
}

export function resetCurrentBar() {
  return {
    type: constants.RESET_CURRENT_BAR,
  };
}
