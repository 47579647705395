import React from "react";
import * as R from "ramda";
import styled from "@emotion/styled/macro";

const Wrapper = styled("div")`
  overflow-y: scroll;
`;

const Selection = styled("div")`
  padding: 5px;
  border-bottom: 1px solid grey;
  cursor: pointer;
  ${(props) => props.selected && `background:lightgrey;`}
`;

class SoundbankItem extends React.Component {
  render() {
    return (
      <Wrapper>
        {R.map((item) => {
          const selectedItem = {
            level: this.props.nextLevel,
            items: item.children,
            selection: item,
          };
          const onClick = () => this.props.onSelect(selectedItem);
          return (
            <Selection
              key={item.name}
              onClick={onClick}
              selected={item.selected}
            >
              {item.name}
            </Selection>
          );
        }, this.props.items)}
      </Wrapper>
    );
  }
}

export default SoundbankItem;
