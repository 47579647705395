import { combineReducers } from 'redux'
import main from './main'
import { reducer as editorReducer } from './editor'
import { reducer as playerReducer } from './player'
import { reducer as tracksReducer } from './tracks'

export default combineReducers({
  main,
  editor: editorReducer,
  player: playerReducer,
  tracks: tracksReducer,
})
