import * as React from "react";
import styled from "@emotion/styled/macro";
import SoundbankItem from "./soundbank-item";
import * as main from "../reducers/main";
import * as Redux from "react-redux";

const Wrapper = styled("div")`
  display: flex;
`;

const SoundbankSelector = (props) => {
  const soundbank = Redux.useSelector((state) => main.getSoundbank(state.main));
  const initialLevels = React.useMemo(() => [soundbank], [soundbank]);
  const [levels, setLevels] = React.useState(initialLevels);

  React.useEffect(() => {
    setLevels(initialLevels);
  }, [initialLevels]);

  React.useEffect(() => {
    if (props.selected) {
      onSelect(props.selected);
    }
  }, [props.selected]);

  const onSelect = (selected) => {
    let updatedLevels = [...levels];
    const paths = selected.selection.path.split("/");
    updatedLevels.forEach((level) => {
      level.forEach((item) => {
        item.selected = undefined;
        paths.forEach((path) => {
          if (item.name === path) {
            item.selected = true;
          }
        });
      });
    });

    if (selected.selection.type === "folder") {
      updatedLevels = updatedLevels.slice(0, selected.level);
      updatedLevels[selected.level] = selected.items;
    }

    if (selected.levels) {
      updatedLevels = selected.levels;
    }

    setLevels(updatedLevels);

    if (!selected.levels && selected.selection.type === "file") {
      selected.levels = updatedLevels;
      props.onFileSelection(selected.selection, selected);
    }
  };

  return (
    <Wrapper>
      {levels.map((item, i) => (
        <SoundbankItem
          items={item}
          onSelect={onSelect}
          nextLevel={i + 1}
          key={i}
        />
      ))}
    </Wrapper>
  );
};

export default SoundbankSelector;
