import * as R from "ramda";
import { openDB } from "idb";

export const normalizeSoundName = R.pipe(
  R.replace(/\./g, "-"),
  R.replace(/\ /g, "-"),
  R.replace(/\//g, "-")
);

// Move to it's own file?
// Create hooks for it etc?
const DB_NAME = "lodaw";
const DB_VERSION = 1;
const OBJECT_STORE_NAME = "audioStore"; // Ok or should be renamed?

export async function setLocalAudio(id, audioBlob) {
  const db = await openDB(DB_NAME, DB_VERSION, {
    upgrade(db) {
      db.createObjectStore(OBJECT_STORE_NAME, {
        keyPath: "id",
        autoIncrement: true,
      });
    },
  });

  const transaction = db.transaction(OBJECT_STORE_NAME, "readwrite");
  const store = transaction.objectStore(OBJECT_STORE_NAME);

  const allAudio = await store.getAll();
  const newAudioArray = allAudio.length > 0 ? allAudio[0].audioArray : [];

  newAudioArray.push({ id, data: audioBlob });

  await store.put({ audioArray: newAudioArray });
}

export async function getLocalAudio() {
  const db = await openDB(DB_NAME, DB_VERSION, {
    upgrade(db) {
      db.createObjectStore(OBJECT_STORE_NAME, {
        keyPath: "id",
        autoIncrement: true,
      });
    },
  });

  const transaction = db.transaction(OBJECT_STORE_NAME, "readonly");
  const store = transaction.objectStore(OBJECT_STORE_NAME);

  const allAudio = await store.getAll();
  return allAudio.length > 0 ? allAudio[0].audioArray : [];
}

// Needed so we don't have to wait for the server to respond with sound id (based on checksum)
async function getBlobUrlChecksum(blobUrl) {
  try {
    // Fetch the content of the Blob URL
    const response = await fetch(blobUrl);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const blob = await response.blob();

    // Convert the blob to an ArrayBuffer
    const arrayBuffer = await blob.arrayBuffer();

    // Calculate the checksum using SHA-256
    const hashBuffer = await crypto.subtle.digest('SHA-256', arrayBuffer);

    // Convert the hash to a hexadecimal string
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');

    return hashHex;
  } catch (error) {
    console.error('Error calculating checksum:', error);
    throw error;
  }
}

// Needed so we don't have to wait for the server to respond with sound id (based on checksum)
export async function generateSoundId(blobUrl, userId) {
  const checksum = await getBlobUrlChecksum(blobUrl);
  return `${userId}-${checksum}.mp3`; // We always save as mp3 (so it can be harcoded)
}
