import React from "react";
import { keyframes } from "@emotion/core";
import styled from "@emotion/styled/macro";
import _Button from "./button";
import { darken } from "polished";
import { Link } from "react-router-dom";
import * as R from "ramda";
import { connect } from "react-redux";
import * as tracks from "../reducers/tracks";
import * as Hooks from "../hooks";
import { FaPause, FaPlay } from "react-icons/fa";

const color1 = "white";
const color2 = "rgba(66, 63, 64, 0.66)";
const color3 = "#4b8aff";

const Wrapper = styled.div`
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  margin: 10px;
  border-radius: 8px;
  overflow: hidden;
  min-width: 150px;
  max-width: 150px;
  border: 1px solid ${color1};
`;

const Item = styled.div`
  position: relative;
  background: white;
  height: 150px;
  overflow: hidden;
  cursor: pointer;
`;

const bgPositionAnimation = keyframes`
  0% {
    background-position: 0 0;
    transform:rotate(0deg);
  }
  100% {
    background-position: -100% 0;
    transform:rotate(360deg);
  }
`;

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  ${(props) =>
    props.id &&
    `
    background:url(https://picsum.photos/200/200?random&id=${props.id});
  `}
  background-size:200%;
  filter: blur(1px) opacity(0.6);
  background-position: 0 0;
  animation: ${bgPositionAnimation} 20s infinite linear;
  ${(props) =>
    !props.isPlaying &&
    `
    animation:0;
  `}

  ${(props) =>
    props.isPlaying &&
    `
    filter: opacity(0.6);
    background-size:100%;
    margin: -50%;
    width: 200%;
    height: 200%;
  `}
`;

const PlayControls = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 50px;
  color: white;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  opacity: 0.8;
`;

const TimeLine = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const Svg = styled.svg`
  width: 80%;
  overflow: visible;
  opacity: 0.8;
`;

const pathDashAnimation = keyframes`
  from {
    stroke-dashoffset: 1000;
  }
  to {
    stroke-dashoffset: 370;
  }
`;

const Path = styled.path`
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: ${pathDashAnimation} ${(props) => props.animationDuration}s linear
    infinite;
  ${(props) =>
    !props.isPlaying &&
    `
    animation:0;
  `}
`;

/* Bottom section */
const Bottom = styled.div`
  height: 35px;
  overflow: hidden;
  position: relative;
  border-top: 1px solid ${color1};
`;

const BottomInner = styled.div`
  position: absolute;
  height: 200%;
  width: 100%;
  ${Wrapper}:hover & {
    transform: translate3d(0, -50%, 0);
  }
  transition: 0.2s ease-out;
`;

const BottomItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
  text-align: center;
  font-weight: bold;
  font-size: 0.8em;
  height: 50%;
`;

const Title = styled(BottomItem)`
  background: ${color1};
  color: ${color2};
`;

const EditButton = styled(BottomItem)`
  cursor: pointer;
  background: ${color3};
  color: ${color1};
  &:hover {
    background: ${darken(0.05, color3)};
  }
`;

function _TrackTile(props) {
  const trackLengthInSeconds = Hooks.useTrackLengthInSeconds();

  return (
    <Wrapper>
      <Item onClick={() => props.onPlay(props.track)}>
        <Background
          isPlaying={props.isPlaying}
          id={R.path(["track", "id"], props)}
        />

        <PlayControls>
          <div className={`fa fa-${props.isPlaying ? "stop" : "play"}`} />
          {props.isPlaying ? <FaPause size="30" /> : <FaPlay size="30" />}
        </PlayControls>

        <TimeLine>
          <Svg
            enable-background="new 0 0 200 200"
            version="1.1"
            viewBox="0 0 200 200"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="xMinYMin meet"
          >
            <defs>
              <filter id="goo">
                <feGaussianBlur
                  in="SourceGraphic"
                  stdDeviation="10"
                  result="blur"
                />
                <feColorMatrix
                  in="blur"
                  mode="matrix"
                  values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7"
                  result="goo"
                />
                <feBlend in="SourceGraphic" in2="goo" />
              </filter>
            </defs>

            <g filter="url('#goo')">
              <Path
                isPlaying={props.isPlaying}
                animationDuration={trackLengthInSeconds}
                d="M100.416,2.001C154.35,2.225,198,46.015,198,100 c0,54.124-43.876,98-98,98S2,154.124,2,100S45.876,2,100,2"
                fill="none"
                stroke={color1}
                strokeMiterlimit="10"
                strokeWidth="10"
                linecap="round"
              />
              <path
                d="M100.416,2.001C154.35,2.225,198,46.015,198,100 c0,54.124-43.876,98-98,98S2,154.124,2,100S45.876,2,100,2"
                fill="none"
                stroke={color1}
                strokeMiterlimit="10"
                strokeWidth="2"
              />
            </g>
          </Svg>
        </TimeLine>
      </Item>

      <Bottom>
        <BottomInner>
          <Title>{props.trackName}</Title>
          <Link to={`/create?trackId=${props.track.id}`}>
            <EditButton onClick={() => props.onEdit(props.track)}>
              {props.editText}
            </EditButton>
          </Link>
        </BottomInner>
      </Bottom>
    </Wrapper>
  );
}

function mapStateToProps(state, props) {
  return {
    track: tracks.getTrack(state.tracks, props.trackId),
    trackName: tracks.getTrackName(state.tracks, props.trackId),
  };
}

function mapDispatchToProps() {
  return {};
}

export const TrackTile = R.compose(
  connect(mapStateToProps),
  connect(null, mapDispatchToProps)
)(_TrackTile);
