import * as R from "ramda";
import { CreateTrack as Component } from "./create-track";
import { connect } from "react-redux";
import { deleteTrack, fetchTrack } from "../../requests";
import * as editor from "../../reducers/editor";
import { store } from "../../..";

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch, props) {
  return {
    // This is needed to be triggered when all sounds are loaded
    // Needed for the editor, when a track is loaded (channels etc loads before the sounds)
    loadRegions() {
      const state = store.getState();
      if (props.trackData) {
        dispatch(editor.updateTrack(props.trackData));
      } else {
        dispatch(editor.updateTrack(state.editor.track));
      }
    },
    onMount: () => {
      if (props.trackData) {
        dispatch(editor.updateTrack(props.trackData));
      }
    },
    onFetchTrack: (trackId) => {
      fetchTrack(trackId, {
        success: (res) => {
          dispatch(editor.updateTrack(res));
        },
        fail: (res) => {},
      });
    },
    onDeleteTrack: (trackId) => {
      deleteTrack(trackId);
    },
  };
}

export const CreateTrack = R.compose(
  connect(mapStateToProps, mapDispatchToProps)
)(Component);
