import { Howl } from "howler";
// import { UPDATE_TRACK } from "../reducers/editor/constants";
import { normalizeSoundName } from "../helpers";
import { getAllSounds } from "../load-sounds";
import * as editor from "../reducers/editor";
import * as main from "../reducers/main";
import { MAIN_AUDIO_CONTEXT, audioSources } from "../effects/audio-sources";
import { clientConfig } from "../client-config";

// Action types
const PLAY = "PLAY";
const STOP = "STOP";
const ADD_REGION = "ADD_REGION";
const ADD_REGIONS = "ADD_REGIONS";
const LOAD_SOUNDS = "LOAD_SOUNDS";
const LOAD_SOUND = "LOAD_SOUND";

// Action creators
export function play(key, volume = 1.0, rate = 1.0, regionName) {
  const action = {
    type: PLAY,
    payload: { key, regionName, volume, rate },
  };

  const soundToPlay = sounds[action.payload.key];

  if (!soundToPlay) {
    console.error("Sound not found (key not found in sounds):", {
      sounds,
      key,
    });
    return;
  }

  // It would be more efficient to do this once and not on every play (if possible)
  // Todo: Fix so it plays in the correct effects channel
  soundToPlay._sounds.forEach((soundElement) => {
    soundElement._node.connect(audioSources.master);
  });

  if (soundToPlay) {
    const volume = action?.payload?.volume || 1;
    soundToPlay.volume(volume);
    try {
      // This seems to cause issues sometimes (but does not affect playing the sound)
      soundToPlay.rate(action?.payload?.rate || 1);
    } catch (error) {}
    if (action.payload.regionName) {
      // Todo: Need to figure out how to fade out sample so it does not cut off abruptly
      soundToPlay.play(action.payload.regionName);

      // Below almost works, seems to have some timing issue
      // fadeOutRegionAtEnd(
      //   soundToPlay,
      //   action.payload.regionName,
      //   volume,
      //   50
      // );
    } else {
      soundToPlay.play();
    }
  }
}

export const stop = (key) => ({
  type: STOP,
  payload: { key },
});

export const addregion = (key, sectionName, start, end) => ({
  type: ADD_REGION,
  payload: { key, sectionName, start, end },
});

export const addregions = (soundId, regions) => {
  const sectionsData = {
    soundId: normalizeSoundName(soundId),
    regions: regions.reduce(
      (acc, region) => ({
        ...acc,
        [region.name]: {
          start: region.start,
          end: region.end,
          name: region.name,
        },
      }),
      {}
    ),
  };

  return {
    type: ADD_REGIONS,
    payload: sectionsData,
  };
};

export const loadSounds = (inputSoundsData) => {
  const soundsArr = getAllSounds(inputSoundsData);
  const soundsData = {};
  soundsArr.forEach((item) => {
    const soundName = normalizeSoundName(item.path);
    soundsData[soundName] = {
      src: item.path,
      id: item.id,
    };
  });

  return {
    type: LOAD_SOUNDS,
    payload: soundsData,
  };
};

export const loadSound = (soundId, blobUrl) => {
  return {
    type: LOAD_SOUND,
    payload: {
      soundId: normalizeSoundName(soundId),
      blobUrl,
    },
  };
};

export const sounds = {};
window.sound = sounds;
export function getHowlerSound(key) {
  return sounds[normalizeSoundName(key)];
}

const audioBlobUrls = {};
export function getAudioBlobUrl(key) {
  return audioBlobUrls[normalizeSoundName(key)];
}

export function getHowlerSoundNode(howlerSoundId) {
  const howlSound = getHowlerSound(howlerSoundId);
  return howlSound._sounds[0]._node;
}

// Force howler to use the main audio context
Howler.autoUnlock = false;
Howler.ctx = MAIN_AUDIO_CONTEXT;
Howler.masterGain = Howler.ctx.createGain();
Howler.masterGain.connect(Howler.ctx.destination);

function getSoundUrl(actionSoundUrl) {
  const localUrl = `${clientConfig.apiUrl}${actionSoundUrl}`;
  const prodUrl = actionSoundUrl;
  return actionSoundUrl.startsWith("http") ? prodUrl : localUrl;
}

async function createAudioBlobUrl(audioFileUrl) {
  try {
    // Fetch the audio file
    const response = await fetch(audioFileUrl);

    // Check if the fetch was successful
    if (!response.ok) {
      return null;
    }

    // Get the audio file as a blob
    const audioBlob = await response.blob();

    // Create and return a blob URL
    return URL.createObjectURL(audioBlob);
  } catch (error) {
    console.error("Error creating blob URL:", error);
    return null;
  }
}

async function addSoundToHowler(item) {
  const id = normalizeSoundName(item.id);
  const url = getSoundUrl(item.src);
  const audioBlobUrl = await createAudioBlobUrl(url);
  if (audioBlobUrl) {
    audioBlobUrls[id] = audioBlobUrl;

    sounds[id] = new Howl({
      src: audioBlobUrl,
      format: ["mp3"],
    });
  } else {
    console.error("Could not load sound:", url);
  }

  // We want to continue loading sounds even if one fails
  return Promise.resolve();
}

async function addSoundsToHowler(soundData) {
  const promises = soundData.map((item) => {
    return addSoundToHowler(item);
  });

  await Promise.all(promises);
  checkAllLoaded(Object.values(sounds));
}

function checkAllLoaded(allSounds) {
  const loadedSounds = allSounds.filter(
    (sound) => sound.state() !== "unloaded"
  );
  const allLoaded = loadedSounds.every((sound) => sound.state() === "loaded");

  if (allLoaded) {
    window.dispatchEvent(
      new CustomEvent("all-sounds-loaded", { detail: { loaded: true } })
    );
  } else {
    setTimeout(() => {
      checkAllLoaded(allSounds);
    }, 300);
  }
}

// Middleware Factory
export const middleware = () => {
  return (store) => (next) => (action) => {
    switch (action.type) {
      case LOAD_SOUNDS:
        const soundData = Object.values(action.payload);
        addSoundsToHowler(soundData);

        break;

      // Used when you add new sound via recording etc
      case LOAD_SOUND:
        console.log("LOAD_SOUND:", action.payload);

        sounds[action.payload.soundId] = new Howl({
          src: [action.payload.blobUrl],
          format: ["mp3"], // Seems to be required for blob urls
        });
        audioBlobUrls[action.payload.soundId] = action.payload.blobUrl;
        break;

      case STOP:
        const soundToStop = sounds[action.payload.key];
        if (soundToStop) {
          soundToStop.stop();
        }
        break;

      // This is for adding regions to Howler
      // So they actually can be played later (not only visual display)
      // - Needed to retry until sounds are loaded
      case ADD_REGIONS:
        const soundToUpdate = getHowlerSound(action.payload.soundId);

        if (soundToUpdate && action.payload.regions) {
          Object.values(action.payload.regions).forEach((region) => {
            const duration = region.end - region.start;
            soundToUpdate._sprite[region.name] = [region.start, duration];
          });
        }

        break;

      case main.UPDATE_TRACK: // For track player (without editor)
      case editor.UPDATE_TRACK: // For editor
        action?.track?.channels?.forEach((channel) => {
          if (channel.regions) {
            store.dispatch(addregions(channel.soundId, channel.regions));
          }
        });
        break;

      default:
        break;
    }

    return next(action);
  };
};

function fadeOutRegionAtEnd(mainSound, spriteName, volume, fadeDuration) {
  // Get the sprite's duration and start time from the Howl object.
  const spriteData = mainSound._sprite[spriteName];
  if (!spriteData) {
    console.error("Sprite not found:", spriteName);
    return;
  }

  // Calculate the sprite's duration and start time.
  const [startOffset, spriteDuration] = spriteData;

  // Play the sprite sound and get its ID.
  const soundId = mainSound.play(spriteName);

  // Calculate when to start the fade effect so that it ends exactly when the sprite ends.
  // Ensure the fade starts at least 0 milliseconds into the sprite's duration.
  const fadeStartTime = Math.max(spriteDuration - fadeDuration, 0);

  // Schedule the fade to start at the calculated time.
  // If the sprite duration is shorter than the fadeDuration, the Math.max above will make fadeStartTime 0.
  setTimeout(() => {
    // Ensure the fade does not last longer than the sprite itself.
    const actualFadeDuration = Math.min(fadeDuration, spriteDuration);
    mainSound.fade(volume, 0, actualFadeDuration, soundId);
  }, fadeStartTime / 2);
}
