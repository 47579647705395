import React from "react";
import * as R from "ramda";
import { BARS_SECTION_AMOUNT, BARS_PER_SECTION_AMOUNT } from "../../constants";
import { BarControl } from "./bar-control";
import { Box } from "../common/box";

export function BarControls(props) {
  const element = React.useRef();
  const [eventType, setEventType] = React.useState(null);
  const [selected, setSelected] = React.useState({});

  React.useEffect(() => {
    function onEvent(e) {
      setSelected({});
      setEventType(e.type);
    }

    function onMouseMove(e) {
      //Throttle this?
      if (e.target != element.current && eventType == "mousedown") {
        setSelected({
          element: e.target,
          value: e.clientY - e.target.getBoundingClientRect().top,
        });
      }
    }

    if (element.current) {
      element.current.addEventListener("mousedown", onEvent);
      element.current.addEventListener("mouseup", onEvent);
      element.current.addEventListener("mouseleave", onEvent);
      element.current.addEventListener("mousemove", onMouseMove);

      return () => {
        // Technically not needed since element.current is removed when unmounting (so it's impossible to remove the listener)
        if (element.current) {
          element.current.removeEventListener("mousedown", onEvent);
          element.current.removeEventListener("mouseup", onEvent);
          element.current.removeEventListener("mouseleave", onEvent);
          element.current.removeEventListener("mousemove", onMouseMove);
        }
      };
    }
  }, [element.current, eventType]);

  return (
    <Box
      position="relative"
      display="flex"
      cursor="pointer"
      height="100%"
      ref={element}
    >
      {R.times(
        (id) => (
          <BarControl
            id={id}
            key={id}
            type={props.controlType}
            selected={selected}
            channelId={props.channelId}
          />
        ),
        BARS_SECTION_AMOUNT * BARS_PER_SECTION_AMOUNT
      )}
    </Box>
  );
}
