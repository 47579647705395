import * as React from "react";
import * as Redux from "react-redux";
import { effects } from "../../effects";
import * as editor from "../../reducers/editor";
import * as Hooks from "../../hooks";
import { useInstances } from "../../../instances-provider";
import { audioSourcesKeys } from "../../effects/audio-sources";

// Updated EffectSelector component
const EffectSelector = ({ channelEffectId, effects, existingEffects }) => {
  const [selectedEffect, setSelectedEffect] = React.useState("");
  const dispatch = Redux.useDispatch();

  const handleAddEffect = () => {
    if (selectedEffect) {
      dispatch(
        editor.updateEffect(channelEffectId, selectedEffect, {})
      );
      setSelectedEffect("");
    }
  };

  return (
    <div className="flex items-center space-x-2">
      <select
        value={selectedEffect}
        onChange={(e) => setSelectedEffect(e.target.value)}
      >
        <option value="">Select an effect</option>
        {Object.keys(effects).map((effectKey) => (
          <option key={effectKey} value={effectKey}>
            {effectKey}
          </option>
        ))}
      </select>
      <button
        onClick={handleAddEffect}
        disabled={!selectedEffect}
      >
        Add Effect
      </button>
    </div>
  );
};

// Updated Effects component
export function Effects(props) {
  const dispatch = Redux.useDispatch();
  const channelEffectId = Redux.useSelector((state) =>
    editor.getChannelEffectId(state.editor, props.channelId)
  );
  const effectsChannel = Redux.useSelector((state) =>
    editor.getEffectsChannel(state, channelEffectId)
  );

  return (
    <div>
      <EffectsChannelSelector
        onChange={(channelEffectId) => {
          dispatch(
            editor.updateChannelEffectId(props.channelId, channelEffectId)
          );
        }}
        defaultValue={channelEffectId}
      />
      {Object.entries(effectsChannel?.effects || []).map(([key, effect]) => (
        <Effect
          key={key + effect.id}
          channelId={props.channelId}
          effectId={effect.id}
          howlerSoundId={props.howlerSoundId}
          channelEffectId={channelEffectId}
          defaultSettings={effect.settings}
        />
      ))}
      <EffectSelector
        channelEffectId={channelEffectId}
        effects={effects}
        existingEffects={effectsChannel?.effects || {}}
      />
    </div>
  );
}

function Effect(props) {
  const dispatch = Redux.useDispatch();
  const defaultEffectSettings = props.defaultSettings || {};
  const instances = useInstances();
  const effectInstance = instances.effects.get(props.channelId, props.effectId);

  const [effectSettings, setEffectSettings] = React.useState(
    defaultEffectSettings
  );

  const handleParameterChange = React.useCallback(
    (paramName, newValue) => {
      if (effectInstance) {
        const newParameters = {
          ...effectInstance.settings,
          [paramName]: parseFloat(newValue),
        };

        if (effectInstance.setParameters) {
          effectInstance.setParameters(newParameters);
          setEffectSettings(newParameters);
        }
      }
    },
    [effectInstance]
  );

  Hooks.useEffectDebounced(
    () => {
      if (effectInstance && effectInstance?.parameters) {
        dispatch(
          editor.updateEffect(
            props.channelEffectId,
            props.effectId,
            effectSettings || {}
          )
        );
      }
    },
    [effectSettings, effectInstance?.parameters],
    100
  );

  React.useEffect(() => {
    if (effectInstance && props.defaultSettings) {
      effectInstance.setParameters(props.defaultSettings);
      setEffectSettings(props.defaultSettings);
    }
  }, [props.defaultSettings]);

  if (!effectInstance) {
    return null;
  }

  return (
    <div>
      <div>{props.effectId}</div>
      {effectInstance.parameters && (
        <div>
          <div>Parameters:</div>
          {Object.keys(effectInstance.parameters).map((paramName) => (
            <div key={paramName}>
              <label>
                {paramName}:
                <EffectInput
                  key={paramName}
                  min={effectInstance.parameters[paramName].range.min}
                  max={effectInstance.parameters[paramName].range.max}
                  value={
                    effectInstance.settings[paramName] ||
                    effectInstance.parameters[paramName].value
                  }
                  onChange={(value) => handleParameterChange(paramName, value)}
                />
              </label>
              <span>
                {" "}
                {effectInstance.settings[paramName] ||
                  effectInstance.parameters[paramName].value}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

function EffectInput(props) {
  const [value, setValue] = React.useState(props.value);
  return (
    <input
      type="range"
      min={props.min}
      max={props.max}
      value={value}
      step={0.01}
      onChange={(e) => {
        props.onChange(e.target.value);
        setValue(e.target.value);
      }}
    />
  );
}

export function EffectsChannelSelector({ onChange, defaultValue }) {
  return (
    <select
      onChange={(e) => onChange(e.target.value)}
      defaultValue={defaultValue}
    >
      {audioSourcesKeys.map((key) => (
        <option key={key} value={key}>
          {key}
        </option>
      ))}
    </select>
  );
}