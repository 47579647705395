import React from "react";
import styled from "@emotion/styled/macro";
import { Button } from "./common/button";
import { Link } from "react-router-dom";
import Login from "./login";
import { Space } from "./common/space";
import { Logo } from "./logo";
import { Flex } from "./common/flex";
import { getExampleTrack } from "../example-track";
import { GetTracks } from "./get-tracks";
import { TrackTileList } from "./track-tile-list";
import { CreatePattern } from "./create-pattern";
import * as Hooks from "../hooks";
import { FaUser } from "react-icons/fa";
import { Box } from "./common/box";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  min-height: 100vh;

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: radial-gradient(
      circle at center -26%,
      #0e2440 22%,
      #000306 53%,
      #16283e 136%
    );
    z-index: -1;
  }
`;

const TextWrapper = styled.div`
  text-align: center;
  padding-top: 50px;
`;

const Title = styled.div`
  color: white;
  font-size: 60px;
`;

const Description = styled.div`
  color: white;
  font-size: 20px;
  margin-top: 8px;
`;

const Top = styled.div`
  // background: rgba(128, 128, 128, 0.9);
`;

const Bottom = styled.div`
  // background: rgba(255, 255, 255, 0.9);
  padding-top: 40px;
  flex: 1;
`;

export function LandingPage(props) {
  const user = Hooks.useUser();

  return (
    <Wrapper>
      <Link to="/create">
        <Button variant="0" absolute={{ top: "20px", right: "20px" }}>
          + Create Track
        </Button>
      </Link>
      {user && (
        <Link to={`/user/${user.id}`}>
          <Button variant="0" absolute={{ top: "80px", right: "20px" }}>
            <FaUser color="grey" size="20px" />
            <Box marginLeft="10px" display="inline-block">
              {user.displayName}
            </Box>
          </Button>
        </Link>
      )}
      <Top>
        <TextWrapper>
          <Flex center flexDirection="column">
            <Logo />
            <Title>Lodaw</Title>
          </Flex>
          <Description>Your music maker online</Description>
        </TextWrapper>
      </Top>
      <Bottom>
        <Flex center>{!user && <Login />}</Flex>
        <Space pt="40px">
          <Flex center flex="1">
            <CreatePattern
              fixedTopBar={false}
              trackData={getExampleTrack("basic")}
            />
          </Flex>
        </Space>
        <Space pt="3" pb="6">
          <GetTracks id="latest-tracks" query={{ type: "latest" }}>
            {(ids) => (
              <TrackTileList
                title="Latest tracks"
                editText={"Remix"}
                trackIds={ids}
              />
            )}
          </GetTracks>
        </Space>
      </Bottom>
    </Wrapper>
  );
}
