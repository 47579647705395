import * as React from "react";
import { hashAndTruncate } from "../components/login";

export const usePasswordProtection = (
  hashedPassword,
  storedHashedPasswordLocalStorageKey = "beta-password"
) => {
  const [isPasswordCorrect, setIsPasswordCorrect] = React.useState(false);
  const [prompted, setPrompted] = React.useState(false);

  React.useEffect(() => {
    const isLocalhost =
      window.location.hostname === "localhost" ||
      window.location.hostname === "127.0.0.1";
    const storedHashedPassword = localStorage.getItem(
      storedHashedPasswordLocalStorageKey
    );

    if (isLocalhost || (storedHashedPassword === hashedPassword && !prompted)) {
      setIsPasswordCorrect(true);
      setPrompted(true);
    } else if (
      hashedPassword &&
      !isLocalhost &&
      !isPasswordCorrect &&
      !prompted
    ) {
      const enteredPassword = prompt("Enter password:");
      hashAndCheckPassword(enteredPassword, hashedPassword);
    }
  }, [isPasswordCorrect, prompted]);

  const hashAndCheckPassword = (enteredPassword, storedHashedPassword) => {
    hashAndTruncate(enteredPassword)
      .then((hashedEnteredPassword) => {
        if (hashedEnteredPassword !== storedHashedPassword) {
          setIsPasswordCorrect(false);
        } else {
          localStorage.setItem(
            storedHashedPasswordLocalStorageKey,
            hashedEnteredPassword
          );
          setIsPasswordCorrect(true);
          setPrompted(true);
        }
      })
      .catch((error) => {
        console.error("Error hashing password:", error);
        setIsPasswordCorrect(false);
      });
  };

  return isPasswordCorrect;
};
