import { lighten, transparentize, darken } from "polished";

/* Colors */
const bg1 = "#eeeeee";
export const bg2 = "#433F40";
const bg3 = "#0558ff";
const bg4 = "rgba(0,0,0,.3)";

/* Heights */
const headerHeight = "50px";

/* Input */
const color1 = lighten(0.4, bg3);
const color2 = "white";
export const color3 = lighten(0.3, bg3);
const color4 = "#a9c5f9";

export const theme = {
  topbarLight: transparentize(0.3, bg1),
};

export const globalStyles = `
    * {
      margin:0 0 0 0;
      padding:0 0 0;
      font-smoothing: antialiased;
      font-family: 'Hammersmith One', sans-serif;
      appearance:none;
      outline:none;
    }

    header {
      background:${bg2};
      height:${headerHeight};
      display: flex;
      align-items:center;
      border-bottom: 2px solid rgba(0,0,0,.2);
      padding-left:10px;

      & .topbar-icon {
        color: ${transparentize(0.3, bg1)};
        font-size:30px;
        cursor:pointer;
      }

      & .topbar-icon:hover {
        color:${transparentize(0.5, bg1)};
      }
    }

    .channels .channel:last-of-type .head {
      box-shadow:inset -1px 0px #757474, 2px 0px 5px rgba(0, 0, 0, .1);
    }

    .channels {
      z-index:1;
      background:#646464;

      & .channel {
        display: flex;

       & .head {
          color: white;
          font-size: 13px;
          width:100px;
          height:100%;
          box-shadow:inset 0px -1px #8b8888, inset -1px 0px #757474, 2px 0px 5px rgba(0, 0, 0, .1);
          display: flex;
          justify-content:center;
          align-items:center;
        }
       & .bars {
          height:100%;
          display: flex;
          flex:1;

         & .bar-group {
            display: flex;
            flex:1;
          }

         & .bar {
            height:100%;
            display: flex;
            flex:1;
            background: rgba(80, 82, 84, 0.7);
            cursor:pointer;
            box-sizing: border-box;
            border-bottom: 1px solid rgba(255,255,255,.1);
            border-right: 1px solid rgba(255,255,255,.1);

            &:not(:last-type) {
              border-bottom:0;
            }
          }

         & .bar:hover,
         & .bar[data-selected="true"] {
           background:${darken(0.2, color3)} !important;
         }

         & .bar[data-selected="true"][data-playing="true"] {
           background: ${darken(0.15, color3)} !important;
         }

          /* Color every fourth element */
          & .bar-group:nth-of-type(even) .bar {
            background: rgba(112, 113, 116, 0.55);
          }

          & .bar-group:nth-of-type(even) .bar:hover,
          & .bar-group:nth-of-type(even) .bar:checked {
            background:${transparentize(0.7, bg3)};
          }

          & .bar:active {
            background:${transparentize(0.8, bg3)};
          }


          & .bar[isPlaying='true'],
          & .bar-group:nth-of-type(even) .bar[isPlaying='true'] {
            background:${bg2};
          }

          & .separator {
            width: 5px;
            background: rgba(0,0,0,30%);
            margin-left: 3px;
          }
        }
      }

    }

    .add-channel {
      & .add-icon {
        position: relative;
        color: ${bg4};
        font-size: 13px;
        cursor: pointer;
        opacity: .6;
        margin: 10px;

        &:hover {
          opacity:1;
        }
      }

      .input {
        display:none;
        padding-top: 10px;
        padding-bottom:10px;
        background:${color1};

        &[display="true"] {
          display:flex;
        }

        & .button {
          background:${color2};
          color:${color3};
          padding:10px;
          font-size:20px;
          margin-left:10px;

          &:hover,
          &:hover .select-sounds {
            cursor:pointer;
          }


          & .select-sounds {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            border:0px;
            border-radius:0px;
            color:${color3};
            font-size:20px;
            background:transparent;
            cursor:pointer;
          }
        }
      }

    }
  `;
