import React, { useEffect } from "react";
import * as Hooks from "../../hooks";
import { CreatePattern } from "../create-pattern";
import { Box } from "../common/box";
import { CreateTimeline } from "./create-timeline";
import { EffectsChannels } from "./effects-channels";
import { Bounce } from "../bounce";
import { Header } from "./header";
import { AudioUploaderAndPlayer } from "../audio-uploader-and-player";
import { getExampleTrack } from "../../example-track";
import { SamplesLibrary } from "./samples-library";
import { store } from "../../..";
import Companion from "../companion";

export function CreateTrack(props) {
  const [query] = Hooks.useQueryParams();
  const sideBySideView = Hooks.useMediaQuery("(min-width: 1120px)");

  useEffect(() => {
    if (query.trackId) {
      props.onFetchTrack(query.trackId);
    }
    // else: We shoud start a new track if ther is no track id provided either in query or props
  }, [query.trackId]);

  useEffect(() => {
    props.onMount(query.trackId);

    console.log('CreateTrack - create eventListener for "all-sounds-loaded"');
    window.addEventListener("all-sounds-loaded", props.loadRegions);

    return () => {
      window.removeEventListener("all-sounds-loaded", props.loadRegions);
    };
  }, []);

  return (
    <Box>
      <Header trackId={query.trackId} />
      <Box
        padding="20px"
        display="grid"
        gridTemplateColumns={sideBySideView ? "1fr 1fr" : "1fr"} // Single column on small screens, two columns on larger screens
        gridTemplateRows="auto auto"
        style={{ gap: "20px", rowGap: "20px" }}
      >
        <CreatePattern
          trackData={query.trackId ? null : getExampleTrack("basic")}
        />
        <EffectsChannels />
        <CreateTimeline />
        <SamplesLibrary />
      </Box>

      <Bounce />

      {/* Only for debugging, to easily delete a track etc */}
      {/* {query.trackId && (
          <div
            style={{
              cursor: "pointer",
              padding: "20px",
            }}
            onClick={() => props.onDeleteTrack(query.trackId)}
          >
            Delete track (only for debugging so far)
          </div>
        )} */}
      <Companion />
    </Box>
  );
}


CreateTrack.defaultProps = {
  fixedTopBar: true,
};
