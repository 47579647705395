import {
  BARS_PER_SECTION_AMOUNT,
  BARS_SECTION_AMOUNT,
  DEFAULT_TEMPO,
} from "../constants";
import * as player from "../reducers/player";
import * as editor from "../reducers/editor";
import * as Redux from "react-redux";

// Right now this is not needed to be a hook,
// but this should later be based on dynamic data.
// Specifically when the tempo can be changed (should be dynamic instead of static DEFAULT_TEMPO)
export function useTrackLengthInSeconds() {
  const playType = Redux.useSelector((state) =>
    player.getPlayType(state.player)
  );
  const timelineBarsAmount = Redux.useSelector(editor.getTimelineLoopLength);
  const bpm = DEFAULT_TEMPO; // Todo: Should be dynamic

  return playType === "timeline"
    ? ((timelineBarsAmount / BARS_PER_SECTION_AMOUNT) * 60) / bpm
    : ((bpm / 60) * (BARS_SECTION_AMOUNT / BARS_PER_SECTION_AMOUNT)) / 2;
}
