// Note: Only env variables starting with REACT_APP_ is picked up
// by the client, so secrets etc is safe and not exposed.
const { REACT_APP_API_URL = "", REACT_APP_GOOGLE_CLIENT_ID = "" } =
  window.clientConfig || process.env;

export const clientConfig = {
  apiUrl: REACT_APP_API_URL,
  google: {
    clientId: REACT_APP_GOOGLE_CLIENT_ID,
  },
};
