import * as R from "ramda";

export function getQueryIds(state, queryId) {
  return R.pathOr([], ["queries", queryId], state);
}

export function getTrack(state, id) {
  return R.pathOr({}, ["items", id], state);
}

// Defaulting to first pattern name if no track name
export function getTrackName(state, id) {
  const track = getTrack(state, id);
  const returnValue = track.name || track.patterns[0].name;
  return returnValue;
}

export function getChannels(state, trackId) {
  const track = getTrack(state, trackId);
  return R.pathOr([], ["channels"], track);
}

export function getChannel(state, trackId, channelId) {
  const channels = getChannels(state, trackId);
  return R.path([channelId], channels);
}

export function getBar(state, patternId, trackId, channelId, barId) {
  return R.path(
    [
      "items",
      trackId,
      "patterns",
      patternId,
      "channels",
      channelId,
      "bars",
      barId,
    ],
    state
  );
}
