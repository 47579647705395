import * as constants from "./constants";

export function addChannel(name, soundId, type) {
  return {
    type: constants.ADD_CHANNEL,
    channel: {
      name,
      soundId,
      bars: [],
      type,
    },
  };
}

export function updateChannel(id, name, soundId, regions) {
  return {
    type: constants.UPDATE_CHANNEL,
    channel: {
      id,
      name,
      soundId,
      regions,
    },
  };
}

export function updateChannelEffectId(id, effectId) {
  return {
    type: constants.UPDATE_CHANNEL,
    channel: {
      id,
      // Needs to be a string for comparison later
      effectId: effectId.toString(),
    },
  };
}

export function updateBar(patternId, channelId, barId, payload) {
  return {
    type: constants.UPDATE_BAR,
    patternId,
    channelId,
    barId,
    payload,
  };
}

export function updateChannels(channels) {
  return {
    type: constants.UPDATE_CHANNELS,
    channels,
  };
}

export function clearTrack() {
  return {
    type: constants.CLEAR_TRACK,
  };
}

export function updateTrack(track) {
  return {
    type: constants.UPDATE_TRACK,
    track,
  };
}

export function updatePattern(patternId, pattern) {
  return {
    type: constants.UPDATE_PATTERN,
    patternId,
    pattern,
  };
}

export function addPattern(patternId, patternName) {
  return {
    type: constants.ADD_PATTERN,
    // patternId,
    // patternName,
  };
}

export function updateTimelinePatternStart(
  patternId,
  patternStartTime,
  newStartTime
) {
  return {
    type: constants.UPDATE_TIMELINE_PATTERN_START,
    patternId,
    patternStartTime,
    newStartTime,
  };
}

export function addTimelinePattern(patternId, startTime) {
  return {
    type: constants.ADD_TIMELINE_PATTERN,
    patternId,
    startTime,
  };
}

export function removeTimelinePattern(patternId, startTime) {
  return {
    type: constants.REMOVE_TIMELINE_PATTERN,
    patternId,
    startTime,
  };
}

export function updateCurrentPatternId(patternId) {
  return {
    type: constants.UPDATE_CURRENT_PATTERN_ID,
    payload: patternId,
  };
}

export function updateEffect(effectChannelId, effectId, settings) {
  return {
    type: constants.UPDATE_EFFECT,
    // Important that it's string for comparison (so it's always string, not number)
    effectChannelId: effectChannelId.toString(),
    effectId: effectId.toString(),
    settings,
  };
}
