import * as React from "react";

const InstancesContext = React.createContext({
  effects: {
    value: {},
    setValue: () => {},
    add: () => {},
    remove: () => {},
  },
});

export function useInstances() {
  const context = React.useContext(InstancesContext);
  if (context === null) {
    throw new Error("useInstances must be used within a InstancesProvider");
  }
  return context;
}

function getEffectInstanceId(channelId, effectId) {
  return `${channelId}-${effectId}`;
}

export function InstancesProvider({ children }) {
  const [effectsInstances, setEffectsInstances] = React.useState({});

  const getEffectInstance = React.useCallback(
    (channelId, effectId) => {
      const key = getEffectInstanceId(channelId, effectId);
      return effectsInstances[key];
    },
    [effectsInstances]
  );

  const addEffectsInstance = React.useCallback(
    (channelId, effectId, newEffectsInstances) => {
      const key = getEffectInstanceId(channelId, effectId);
      setEffectsInstances((existingValue) => ({
        ...existingValue,
        [key]: newEffectsInstances,
      }));
    },
    []
  );

  const removeEffectsInstance = React.useCallback((channelId, effectId) => {
    const key = getEffectInstanceId(channelId, effectId);
    setEffectsInstances((existingValue) => {
      const newValue = { ...existingValue };
      delete newValue[key];
      return newValue;
    });
  }, []);

  return (
    <InstancesContext.Provider
      value={{
        effects: {
          value: effectsInstances,
          get: getEffectInstance,
          add: addEffectsInstance,
          remove: removeEffectsInstance,
        },
      }}
    >
      {children}
    </InstancesContext.Provider>
  );
}
