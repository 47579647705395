import * as React from "react";
import { Box } from "../common/box";
import * as Hooks from "../../hooks";
import * as editor from "../../reducers/editor";
import * as Redux from "react-redux";

export function SelectPattern() {
  const [currentPatternId, setCurrentPatternId] = Hooks.useCurrentPattern();
  const patterns = Redux.useSelector(editor.getPatterns);

  return (
    <Box
      marginRight="5px"
      as="select"
      onChange={(event) => {
        setCurrentPatternId(event.target.value);
      }}
    >
      {Object.entries(patterns || {}).map(([patternId, pattern]) => (
        <option
          value={patternId}
          selected={patternId == currentPatternId && "selected"}
          key={patternId}
        >
          {pattern.name}
        </option>
      ))}
    </Box>
  );
}
