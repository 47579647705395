import * as React from "react";

// Could be replaced with https://mui.com/material-ui/react-use-media-query/ or https://usehooks.com/usemediaquery
export const useMediaQuery = (query) => {
  const getMatches = (query) => {
    // Safely check if window is defined for SSR
    if (typeof window !== "undefined") {
      return window.matchMedia(query).matches;
    }
    return false;
  };

  const [matches, setMatches] = React.useState(getMatches(query));

  React.useEffect(() => {
    const mediaQuery = window.matchMedia(query);
    const updateMatches = () => {
      setMatches(mediaQuery.matches);
    };

    mediaQuery.addEventListener("change", updateMatches);

    return () => mediaQuery.removeEventListener("change", updateMatches);
  }, [query]);

  return matches;
};
