import React from "react";
import { Editor } from "../editor";
import * as editor from "../../reducers/editor";
import { EditorWrapper } from "../editor/editor-wrapper";
import TopBar from "../editor/top-bar";
import { SelectPattern } from "./select-pattern";
import Button from "../button";
import { Box } from "../common/box";
import * as Redux from "react-redux";
import * as Hooks from "../../hooks";

export function CreatePattern(props) {
  const [currentPatternId] = Hooks.useCurrentPattern();

  React.useEffect(() => {
    props.onMount();
  }, []);

  return (
    <EditorWrapper>
      <TopBar
        playType="pattern"
        patternId={currentPatternId}
        buttons={<TopbarButtons />}
      />
      <Editor />
    </EditorWrapper>
  );
}

function TopbarButtons() {
  const dispatch = Redux.useDispatch();
  const patterns = Redux.useSelector(editor.getPatterns);
  const [currentPatternId, setCurrentPatternId] = Hooks.useCurrentPattern();

  return (
    <Box display="flex">
      <SelectPattern />
      <Button
        onClick={() => {
          dispatch(editor.addPattern());
          setCurrentPatternId(Object.values(patterns).length);
        }}
      >
        New
      </Button>
    </Box>
  );
}

CreatePattern.defaultProps = {
  fixedTopBar: true,
};
