import * as React from 'react'
import { MAIN_AUDIO_CONTEXT, audioSources } from '../effects/audio-sources';
import * as Redux from 'react-redux';
import * as player from "../reducers/player";
import * as editor from "../reducers/editor";

// Todo:
// - Fix so it records effects (probably issue with not connecting to master)
// - Fix so master records all sounds
// -
function bounceAndDownloadFile(soundSourceKey) {
  console.log("Bouncing to file!");

  // 1. Setup audio context and destination
  const audioCtx = MAIN_AUDIO_CONTEXT;
  const destination = audioCtx.createMediaStreamDestination();

  // Works for now (should make like below to work instead)
  audioSources[soundSourceKey].connect(destination);

  // Connect all nodes in the audio context to the destination
  // audioCtx?.nodes?.forEach(node => {
  //     node.connect(destination);
  // });

  // 2. Setup MediaRecorder with browser's default MIME type
  const mediaRecorder = new MediaRecorder(destination.stream);
  let audioChunks = [];

  mediaRecorder.ondataavailable = (event) => {
    if (event.data.size > 0) {
      audioChunks.push(event.data);
    }
  };

  mediaRecorder.onstop = () => {
    const mimeType = mediaRecorder.mimeType;
    const audioBlob = new Blob(audioChunks, { type: mimeType });
    const audioUrl = URL.createObjectURL(audioBlob);
    const downloadLink = document.createElement("a");
    downloadLink.href = audioUrl;
    downloadLink.download =
      "recorded-audio" + (mimeType.includes("wav") ? ".wav" : ".webm");
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return mediaRecorder;
}

const bounceInputSources = ['master', '1', '2', '3']

export function Bounce() {
  const dispatch = Redux.useDispatch();
  const [bounceInputSource, setBounceInputSource] = React.useState('master');
  const timelineBarsAmount = Redux.useSelector(editor.getTimelineLoopLength);
  const mediaRecorder = React.useMemo(() => bounceAndDownloadFile(bounceInputSource),[bounceInputSource])
  const currentBar = Redux.useSelector((state) => player.getCurrentBar(state.player, "editor"))

  const handleInputChange = (event) => {
    setBounceInputSource(event.target.value);
  };

  React.useEffect(() => {
    if(mediaRecorder.state === 'recording' && currentBar===timelineBarsAmount-1) {
      mediaRecorder.stop();
      dispatch(player.togglePlay(false, "editor", "timeline"))
    }
  }, [mediaRecorder.state, currentBar, timelineBarsAmount])


  return (
    <div style={{
      marginLeft:'20px',
      marginBottom: '20px'
    }}>
      <button onClick={() => {
        mediaRecorder.start();
        dispatch(player.togglePlay(true, "editor", "timeline"))
      }}>Bounce</button>
      <select
        value={bounceInputSource}
        onChange={handleInputChange}
        style={{marginLeft: '5px'}}
      >
        {bounceInputSources.map((source) => (
          <option key={source} value={source}>
            {source}
          </option>
        ))}
      </select>
    </div>
  );
}


function calculateTrackLength(timelineBarsAmount, trackBpm, timeSignature="4/4") {
    // Calculate beats per bar based on time signature
    var beatsPerBar = parseInt(timeSignature.split("/")[0]);

    // Calculate total number of bars
    var totalBars = timelineBarsAmount / beatsPerBar;

    // Calculate duration of one beat in milliseconds
    var beatDurationMs = 60000 / trackBpm;

    // Calculate total length of the track in milliseconds
    var totalLengthMs = totalBars * beatsPerBar * beatDurationMs;

    return totalLengthMs;
}
