import * as React from "react";
import * as editor from "../reducers/editor";
import * as Redux from "react-redux";

export function useCurrentPattern() {
  const currentPatternId = Redux.useSelector(editor.getCurrentPatternId);
  const dispatch = Redux.useDispatch();
  const updateCurrentPatternId = React.useCallback(
    (patternId) => dispatch(editor.updateCurrentPatternId(patternId)),
    [dispatch]
  );

  return [currentPatternId, updateCurrentPatternId];
}
