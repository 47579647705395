import * as constants from './constants'

export function updateTracks(payload) {
  return {
    type: constants.UPDATE_TRACKS,
    payload
  }
}

export function updateQuery(payload) {
  return {
    type: constants.UPDATE_QUERY,
    payload
  }
}

//Remove tracks on Unmount for <GetTrack> to clear memory?
