import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import React from "react";
import { createRoot } from 'react-dom/client';
import rootReducer from "./client/reducers";
import { createStore, applyMiddleware, compose } from "redux";
import dynamicMiddlewares from "redux-dynamic-middlewares";
import { routerMiddleware as createRouterMiddleware } from "react-router-redux";
import { createBrowserHistory } from "history";
import { ThemeProvider } from "emotion-theming";
import { theme } from "./client/theme";

import Routes from "./client/components/routes";

import { injectGlobal } from "emotion";
import { globalStyles } from "./client/global-styles";
import { TrackPlayer } from "./client/components/track-player";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { clientConfig } from "./client/client-config";
import { InstancesProvider } from "./instances-provider";

injectGlobal`
  ${globalStyles}
`;

const history = createBrowserHistory();
const routerMiddleware = createRouterMiddleware(history);

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  rootReducer,
  composeEnhancer(
    applyMiddleware(dynamicMiddlewares),
    applyMiddleware(routerMiddleware)
  )
);

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <InstancesProvider>
      <TrackPlayer />
      <ThemeProvider theme={theme}>
        <GoogleOAuthProvider clientId={clientConfig.google.clientId}>
          <Routes />
        </GoogleOAuthProvider>
      </ThemeProvider>
    </InstancesProvider>
  </Provider>
);
