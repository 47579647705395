import React from "react";
import { Box } from "../common/box";
import { Text } from "../common/text";
import { Flex } from "../common/flex";
import { GetTracks } from "../get-tracks";
import { TrackTileList } from "../track-tile-list";

export function UserPage(props) {
  //const { id } = useParams() //will be used later to feth other than logged in user

  return (
    <Box pt="4">
      <Flex center flexDirection="column">
        <Text fontSize="1">Username: {props.user.displayName}</Text>
        <Text fontSize="1">User id: {props.user.id}</Text>
        <Text fontSize="1">User tracks: {props.user.tracks}</Text>
        {/* <TrackTileList
                    editText={'Remix'}
                    tracks={this.props.tracks}
                /> */}
        <GetTracks
          id="user-tracks"
          query={{
            type: "user",
            id: props.user.id,
          }}
        >
          {(ids) => <TrackTileList editText={"Continue"} trackIds={ids} />}
        </GetTracks>
      </Flex>
    </Box>
  );
}
