import * as R from "ramda";
import * as constants from "./constants";
import { DEFAULT_TEMPO } from "../../constants";

export const DEFAULT_EFFECTS = [
  {
    id: "master", // All other has number
    effects: [],
    volume: 1, // Not used yet (maybe it should be in the effects array?)
  },
];

const initialState = {
  track: {
    name: "",
    bpm: DEFAULT_TEMPO,
    editing: false,
    channels: [],
    patterns: [],
    timeline: [],
    effects: DEFAULT_EFFECTS,
    // example:
    // effects: [
    //   {
    //     id: 'master', // All other has number
    //     volume: 1,
    //     effects: [
    //       effectId: 'bigEcho',
    //       settings: // Same as set when in use
    //     ]
    //   }
    // ]
  },
  currentPatternId: 0,
};

export function reducer(state = initialState, action) {
  const mergeWithState = R.merge(state);

  switch (action.type) {
    case constants.ADD_CHANNEL:
      action.channel.id = state.track.channels.length;
      return mergeWithState({
        track: R.merge(state.track, {
          channels: state.track.channels.concat(action.channel),
        }),
      });
    case constants.UPDATE_CHANNEL:
      const updateIndex = R.findIndex(R.propEq("id", action.channel.id))(
        state.track.channels
      );
      const updatedChannels = state.track.channels;
      updatedChannels[updateIndex] = R.merge(
        updatedChannels[updateIndex],
        action.channel
      );
      return R.mergeDeepLeft(state, { track: { channels: updatedChannels } });
    case constants.UPDATE_BAR:
      const patterns = state.track.patterns;
      const newChannel = {
        channelId: action.channelId,
        bars: [],
      };
      const existingChannels = patterns[action.patternId]?.channels;
      const channels = existingChannels || [];
      const channel = channels[action.channelId] || newChannel;
      const barId = action.barId;
      const existingBar = channel?.bars[barId] || {};

      const updatedBar = {
        ...existingBar,
        ...R.pick(
          ["selected", "regionName", "rate", "volume"],
          action.payload || {}
        ),
      };

      channel.bars[barId] = updatedBar;
      channels[action.channelId] = channel;
      patterns[action.patternId].channels = channels;
      return R.mergeDeepLeft(state, { track: { patterns } });

    case constants.UPDATE_PATTERN:
      const updatedPatterns = Object.assign({}, state.track.patterns); // Copy object to not alter state
      const existingPattern = updatedPatterns[action.patternId];
      updatedPatterns[action.patternId] = {
        ...existingPattern,
        ...action.pattern,
      };

      return R.mergeDeepRight(state, {
        track: { patterns: updatedPatterns },
      });
    case constants.UPDATE_TRACK:
      return mergeWithState({
        track: R.merge(state.track, action.track),
      });
    case constants.CLEAR_TRACK:
      return mergeWithState({ track: initialState.editor.track });

    case constants.UPDATE_CURRENT_PATTERN_ID:
      return mergeWithState({ currentPatternId: action.payload });

    case constants.ADD_PATTERN:
      const patternsLength = Object.values(state.track.patterns).length;
      return R.mergeDeepRight(state, {
        track: {
          patterns: {
            ...state.track.patterns,
            [patternsLength]: {
              name: "Pattern " + (patternsLength + 1),
              channels: [],
            },
          },
        },
      });

    case constants.UPDATE_TIMELINE_PATTERN_START:
      return R.mergeDeepRight(state, {
        track: {
          timeline: state.track.timeline.map((timelinePattern) => {
            const isTimelinePatternToUpdate =
              timelinePattern.patternId === action.patternId &&
              timelinePattern.startTime === action.patternStartTime;
            return isTimelinePatternToUpdate
              ? {
                  ...timelinePattern,
                  startTime: action.newStartTime,
                }
              : timelinePattern;
          }),
        },
      });

    case constants.ADD_TIMELINE_PATTERN:
      return R.mergeDeepRight(state, {
        track: {
          timeline: [
            ...state.track.timeline,
            {
              patternId: action.patternId,
              startTime: action.startTime,
            },
          ],
        },
      });

    case constants.REMOVE_TIMELINE_PATTERN:
      return R.mergeDeepRight(state, {
        track: {
          timeline: state.track.timeline.filter((pattern) => {
            const shouldRemove =
              String(pattern.patternId) === String(action.patternId) &&
              String(pattern.startTime) === String(action.startTime);
            return !shouldRemove;
          }),
        },
      });

    case constants.UPDATE_EFFECT:
      console.log("\n\n##########################################");
      console.log("- state.track.effects:", state.track.effects);
      const effectId = action.effectId;
      const effect = action.effect;
      const effectChannelId = action.effectChannelId;
      // Todo: effectIndex not workin yet (only works to add effect first time, not update)
      const effectIndex = R.findIndex(R.propEq("id", effectId))(
        state.track.effects
      );
      const newEffectIndex =
        effectIndex === -1
          ? Object.keys(state.track.effects).length
          : effectIndex;
      const updatedEffects = Object.assign({}, state.track.effects); // Copy object to not alter state;

      // Not working as expected
      // Cases
      // - Adding effect to a new effectchannel
      // - Adding a second effect to an existing effectchannel
      updatedEffects[effectChannelId] = updatedEffects[newEffectIndex]
        ? R.merge(updatedEffects[newEffectIndex], effect)
        : {
            id: effectChannelId,
            effects: [
              {
                id: effectId,
                settings: action.settings,
              },
            ],
          };

      // console.log("Editor reducer - constants.UPDATE_EFFECT");
      // console.log("- state.track.effects:", state.track.effects);
      // console.log("- effectId:", effectId);
      // console.log("- newEffectIndex:", newEffectIndex);
      // console.log("Merging with state: ", {
      //   track: { effects: updatedEffects },
      // });
      return R.mergeDeepRight(state, { track: { effects: updatedEffects } });

    default:
      return state;
  }
}
