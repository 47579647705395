export const ADD_CHANNEL = "EDITOR/ADD_CHANNEL";
export const UPDATE_CHANNEL = "EDITOR/UPDATE_CHANNEL";
export const UPDATE_CHANNEL_EFFECT_ID = "EDITOR/UPDATE_CHANNEL_EFFECT_ID";
export const UPDATE_BAR = "EDITOR/UPDATE_BAR";
export const UPDATE_CHANNELS = "EDITOR/UPDATE_CHANNELS";
export const UPDATE_TRACK = "EDITOR/UPDATE_TRACK";
export const UPDATE_PATTERN = "EDITOR/UPDATE_PATTERN";
export const CLEAR_TRACK = "EDITOR/CLEAR_TRACK";
export const ADD_PATTERN = "EDITOR/ADD_PATTERN";
export const ADD_BAR_TO_PATTERN = "EDITOR/ADD_BAR_TO_PATTERN"; // Probably not used (remove)
export const UPDATE_TIMELINE = "EDITOR/UPDATE_TIMELINE";
export const UPDATE_TIMELINE_PATTERN_START =
  "EDITOR/UPDATE_TIMELINE_PATTERN_START";

export const ADD_TIMELINE_PATTERN = "EDITOR/ADD_TIMELINE_PATTERN";
export const REMOVE_TIMELINE_PATTERN = "EDITOR/REMOVE_TIMELINE_PATTERN";
export const UPDATE_CURRENT_PATTERN_ID = "EDITOR/UPDATE_CURRENT_PATTERN_ID";
export const UPDATE_EFFECT = "EDITOR/UPDATE_EFFECT";
