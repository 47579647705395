import React from "react";

export function useFetch(url, options, defaultValue) {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState(defaultValue || null);
  const [error, setError] = React.useState(null);
  const [tryNumber, setTryNumber] = React.useState(0); // Changed to 0

  const reFetch = React.useCallback(() => {
    setTryNumber((prevTryNumber) => prevTryNumber + 1);
  }, []);

  React.useEffect(() => {
    if (tryNumber > 0) {
      // Changed to > 0
      setLoading(true);
      setError(null);

      window
        .fetch(url, {
          ...options,
          headers: options.headers,
        })
        .then(async (res) => {
          if (res.ok) {
            setData(await res.json());
            setLoading(false);
            setError(null);
          } else {
            const errorResponse = await res.json();
            setData(defaultValue || null);
            setLoading(false);
            setError(errorResponse.error || "An error occurred");
          }
        })
        .catch((err) => {
          setData(defaultValue || null);
          setLoading(false);
          setError(err);
        });
    }
  }, [tryNumber, url, defaultValue]);

  const memoizedValue = React.useMemo(
    () => ({
      loading,
      data,
      error,
      fetch: reFetch,
    }),
    [loading, data, error, reFetch]
  );

  return memoizedValue;
}