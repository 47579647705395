import React from "react";
import Bar from "./bar";
import { BARS_SECTION_AMOUNT, BARS_PER_SECTION_AMOUNT } from "../constants";
import styled from "@emotion/styled/macro";
import * as Redux from "react-redux";
import * as Player from "../reducers/player";

const Wrapper = styled.div`
  ${(props) => props.fixedHeight && "max-height:40px"}
`;

export default function Bars(props) {
  const playType = Redux.useSelector((state) =>
    Player.getPlayType(state.player)
  );
  let barKey = -1;
  return (
    <Wrapper className="bars" fixedHeight={!props.type}>
      {[...Array(BARS_SECTION_AMOUNT)].map((item, a) => (
        <div className="bar-group" key={a}>
          {[...Array(BARS_PER_SECTION_AMOUNT)].map(() => {
            barKey++;
            return (
              <Bar
                key={barKey}
                id={barKey}
                channelId={props.channelId}
                isPlaying={
                  props.currentBar > -1 &&
                  props.currentBar == barKey &&
                  playType == "pattern"
                }
                type={props.type}
                regionName={props.regionName}
              />
            );
          })}
        </div>
      ))}
    </Wrapper>
  );
}
