import * as React from "react";
import * as R from "ramda";

// This should be replace with ReactRouter.useSearchParams when upgrade is done!
export function useSearchParams() {
  const getSearchParamsObj = () =>
    Object.fromEntries(new URLSearchParams(window.location.search));

  const [searchParams, setSearchParams] = React.useState(getSearchParamsObj());

  React.useEffect(() => {
    const onPopState = () => {
      setSearchParams(getSearchParamsObj());
    };

    window.addEventListener("popstate", onPopState);

    return () => {
      window.removeEventListener("popstate", onPopState);
    };
  }, []);

  const setSearchParamsObj = (newParamsObj) => {
    const updatedSearchString = new URLSearchParams(newParamsObj).toString();
    window.history.pushState({}, "", "?" + updatedSearchString);
    setSearchParams(newParamsObj);
  };

  return [searchParams, setSearchParamsObj];
}

// This is taken from search-admin
// Make sure to publish the ORIGINAL code to npm via new repo etc
// --------------------------------------------------------------
// The code below has been modified to not use ReactRouter.useSearchParams
export function useQueryParams() {
  const [searchParamsObj, setSearchParamsObj] = useSearchParams();

  const updateSearchParams = React.useCallback(
    (newSearchParamsObj) => {
      const currentSearchParamsObj = searchParamsObj;
      const updatedParams = R.reject(R.isNil)({
        ...currentSearchParamsObj,
        ...newSearchParamsObj,
      });

      setSearchParamsObj(updatedParams);
    },
    [searchParamsObj]
  );

  return [searchParamsObj, updateSearchParams];
}
