import * as R from 'ramda'
import { UserPage as Component } from './user-page'
import { connect } from 'react-redux'
import { fetchTrack } from '../../requests'
import * as editor from '../../reducers/editor'
import { getUser } from '../../reducers/main'

function mapStateToProps(state) {
    return {
        user: getUser(state.main)
    }
}

function mapDispatchToProps(dispatch, props) {
    return {
    }
}

export const UserPage = R.compose(
    connect(mapStateToProps),
    connect(null, mapDispatchToProps)
)(Component)