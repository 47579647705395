export const CHANNEl_TYPE_RATE = "rate";
export const CHANNEl_TYPE_VOLUME = "volume";
export const CHANNEL_TYPE_SAMPLE_SLICER = "sample-slicer";
export const CHANNEL_TYPE_EFFECTS = "effects";
//Todo: Both channel types (more like channel features) should work at the same time later
export const USER_STORAGE_KEY = "USER";

export const DEFAULT_TEMPO = 170;
export const BARS_SECTION_AMOUNT = 8;
export const BARS_PER_SECTION_AMOUNT = 4;

//Events
export const PLAY_CHANNEL_EVENT = "track-player/play-channel";

export const BAR_PITCH_VALUES = [
  1.5, 1.4, 1.3, 1.2, 1.1, 1, 0.9, 0.8, 0.7, 0.6, 0.5,
];

export const BAR_VOLUME_VALUES = [...Array(20)].map((item, i) => 1 / i);
