import { v1 as uuid } from "uuid";
import * as R from "ramda";

// Todo: Create a good basic example track
// - Maybe just create it with the base user and then request it from the server

const basic = {
  name: "",
  editing: true,
  channels: [
    {
      name: "DNB_BREAK_08.wav",
      soundId:
        "50-4af46686afd76cf06c6db22cecf93b73aeafc012e49d6e9676f217b0445ba5ff.wav",
      id: 0,
    },
    {
      name: "DNB_BREAK_33.wav",
      soundId:
        "50-9c406f8b725a130886b6bf8926f1c86af87fc7494da27b34e01cfdfe7b408669.wav",
      id: 1,
    },
    {
      name: "Rock Pad.wav",
      soundId:
        "50-9c8c5c7c46fc98e65ab7dbc134a74f90d66d12fbcf32266133c0e5ef81e0bdaa.wav",
      id: 2,
    },
    {
      name: "Careless Pad.wav",
      soundId:
        "50-b9f878b27c850c16126f27c1aed7d0ea32b235fc2a0bc4af87778c0ee3888705.wav",
      id: 3,
    },
    {
      name: "God Chord Pad.wav",
      soundId:
        "50-d4d14975f0985f96936cf31dbb8924c7793eed707187a459b794f31f1b51903b.wav",
      id: 4,
    },
  ],
  patterns: [
    {
      name: "Pattern 1",
      channels: [
        // Example contents
        // {
        //   channelId: 0,
        //   bars: [],
        // },
        // {
        //   channelId: 1,
        //   bars: [],
        // },
      ],
    },
  ],
  timeline: [
    {
      patternId: 0,
      startTime: 0, // This reflects on what bar it should start
      // duration: "00:01:00",
    },
  ],
};

const tracks = {
  basic,
};

//Needs to be a function so we generate new uuid every time
export function getExampleTrack(id = "basic") {
  const track = R.pathOr({}, [id], tracks);
  return R.assoc("id", uuid(), track);
}
