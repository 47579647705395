import * as R from "ramda";
import * as constants from "./constants";

const initialState = {
  playing: false,
  currentBar: -1,
};

export function reducer(state = initialState, action) {
  const mergeWithState = R.merge(state);

  switch (action.type) {
    case constants.TOGGLE_PLAY:
      const isSameId = action.payload.id === state.id;
      const toggleValue = isSameId ? !state.playing : true; //If not same id, reset and start play
      const playing = action.payload.playing || toggleValue;
      return mergeWithState({
        playing,
        id: playing ? action.payload.id : undefined,
        playType: action.payload.playType || "pattern",
      });
    case constants.UPDATE_CURRENT_BAR:
      return mergeWithState({ currentBar: action.currentBar });
    case constants.RESET_CURRENT_BAR:
      return mergeWithState({ currentBar: initialState.currentBar });
    default:
      return state;
  }
}
